import React, { useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import {
  useGetDocumentByUuidQuery,
  useGetTocByDocumentUuidQuery,
} from '../../redux/api-slice'
import { SparklesIcon } from '@heroicons/react/24/outline'
import { selectCurrentPage, setDocumentViewerSidebarOpen } from '../../redux/viewer-slice'
import { useSelector, useDispatch } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query'
import SidebarTOCButton from './sidebar-toc-button'
import { Group, Text, CloseButton } from '@mantine/core'

function SidebarToc() {
  const { documentId } = useParams()
  const currentPage = useSelector(selectCurrentPage)
  const { data: tocData, isLoading } = useGetTocByDocumentUuidQuery(
    documentId ? documentId : skipToken
  )
  const { data: document } = useGetDocumentByUuidQuery(
    documentId ? documentId : skipToken
  )
  const [tocQuery, setTocQuery] = useState('')
  const dispatch = useDispatch()

  const onSetToCQuery = useCallback((e) => {
    setTocQuery(e.target.value)
  }, [])

  if (
    document?.job_status === 'PENDING' ||
    document?.job_status === 'PROCESSING'
  ) {
    return (
      <div className={'flex h-full w-full items-start justify-center'}>
        <div
          className={
            'my-12 flex w-56 flex-col items-center gap-2 text-center text-gray-500'
          }
        >
          <SparklesIcon width={32} />
          <div className={'text-black'}>Creating Table of Contents</div>
          <div className={'text-sm'}>
            Provision AI is analysing your document to extract relevant
            information. This will only take a couple of minutes.
          </div>
        </div>
      </div>
    )
  }

  const onClose = () => {
    dispatch(setDocumentViewerSidebarOpen(null))
  }

  return (
    <div className={'p-4'}>
      <Group justify="space-between" mb={8}>
        <Text fz="md" fw={500}>
          Table of Contents
        </Text>
        <CloseButton onClick={onClose} />
      </Group>
      {tocData && tocData.length === 0 && (
        <div className={'my-6 text-center text-sm italic text-gray-400'}>
          No Table of Contents found.
        </div>
      )}
      {isLoading && (
        <div className={'my-6 text-center text-sm italic text-gray-400'}>
          Loading Table of Contents...
        </div>
      )}
      {tocData && tocData.length > 0 && (
        <div className="flex flex-col">
          <div className="relative mb-2 flex-initial items-center">
            <input
              type="text"
              name="search"
              id="search"
              className="block w-full resize-none rounded-md border-gray-300 p-1 focus:border-indigo-500 focus:ring-indigo-500 text-sm"
              placeholder={'Filter table of contents...'}
              value={tocQuery}
              onChange={onSetToCQuery}
            />
          </div>
          <div className="max-h-[50vh] flex-auto overflow-y-scroll bg-white">
            {tocData
              .filter((item) =>
                item.title.toLowerCase().includes(tocQuery.toLowerCase())
              )
              .map((toc) => (
                <SidebarTOCButton
                  key={`${toc.title}${toc.page}`}
                  toc={toc}
                  currentPage={currentPage}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default SidebarToc
