/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { useDispatch, useSelector } from 'react-redux'
import { POSTHOG } from '../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import {
  useGetDocumentsByProjectQuery,
  useGetDocumentStatusByProjectQuery,
  useGetNotificationsQuery,
  useGetProjectsQuery,
  useGetUserProfileQuery,
  useMarkNotificationsAsReadMutation,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import {
  BellAlertIcon,
  BellIcon,
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  Squares2X2Icon,
  UserIcon,
} from '@heroicons/react/24/outline'
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import {
  selectCurrentDocument,
  selectModal,
  selectTermsAndConditionsCompleted,
  setCurrentDocument,
  setCurrentProject,
  setHelpOpen,
} from '../../redux/application-slice'
import { selectCurrentProject } from '../../redux/application-slice'
import { Container, Paper, Divider, Tooltip } from '@mantine/core'
import { useWorkflow } from '../../hooks/use-workflow'
import { Workflow } from '../workflows/workflow-popover/workflow-popover'
import { CircleDot } from '../common/circle-dot'
import { MenuPopover } from './menu-popover'
import { AppMenuPopover } from './app-menu-popover'
import { useNavigateWorkflow } from '../../hooks/use-navigate-workflow'
import { workflows } from '../workflows/workflow-popover/workflow.data'
import { useHotkeys } from 'react-hotkeys-hook'
import { useElementWidth } from '../../hooks/use-element-width'
import { useMenuOptions } from '../../hooks/use-topbar-menu-options-data'
import { truncateText } from '../../utils/truncate-text'
import { MODAL_TYPES } from '../modals/modal-controller'
import { toast } from 'react-toastify'

const ProjectsButtonMenuTarget = forwardRef<
  HTMLDivElement,
  { maxSelectorWidth: number }
>(({ maxSelectorWidth }, ref) => {
  const currentProject = useSelector(selectCurrentProject)
  return (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      data-testid="Projects Dropdown Button"
      className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] !text-gray-500 group-hover:!text-black">
        <div
          className={`flex items-center max-w-[${maxSelectorWidth}px] truncate overflow-ellipsis whitespace-nowrap`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            className="mr-2"
            fill="currentColor"
            viewBox="0 0 256 256"
          >
            {currentProject ? (
              <path d="M216,72H131.31L104,44.69A15.86,15.86,0,0,0,92.69,40H40A16,16,0,0,0,24,56V200.62A15.4,15.4,0,0,0,39.38,216H216.89A15.13,15.13,0,0,0,232,200.89V88A16,16,0,0,0,216,72ZM40,56H92.69l16,16H40ZM216,200H40V88H216Z"></path>
            ) : (
              <path d="M96,208a8,8,0,0,1-8,8H39.38A15.4,15.4,0,0,1,24,200.62V192a8,8,0,0,1,16,0v8H88A8,8,0,0,1,96,208Zm64-8H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.89A15.13,15.13,0,0,0,232,200.89V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM24,80V56A16,16,0,0,1,40,40H92.69A15.86,15.86,0,0,1,104,44.69l29.66,29.65A8,8,0,0,1,128,88H32A8,8,0,0,1,24,80Zm16-8h68.69l-16-16H40Zm-8,88a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z"></path>
            )}
          </svg>
          {truncateText(
            maxSelectorWidth,
            currentProject ? currentProject.title : 'No Selection'
          )}
          <ChevronDownIcon className="mx-2 h-6 w-6 text-gray-500 group-hover:text-black" />
        </div>
      </div>
    </Paper>
  )
})
ProjectsButtonMenuTarget.displayName = 'ProjectsButtonMenuTarget'

const DocumentsButtonMenuTarget = forwardRef<
  HTMLDivElement,
  { maxSelectorWidth: number }
>(({ maxSelectorWidth }, ref) => {
  const currentDocument = useSelector(selectCurrentDocument)
  return (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group cursor-pointer border-2 border-[#ffffff00] bg-[#dadada] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] !text-gray-500 group-hover:!text-black ">
        {currentDocument ? (
          <div
            className={`flex items-center max-w-[${maxSelectorWidth}px] truncate overflow-ellipsis whitespace-nowrap`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              className="mr-1"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <path d="M213.66,82.34l-56-56A8,8,0,0,0,152,24H56A16,16,0,0,0,40,40V216a16,16,0,0,0,16,16H200a16,16,0,0,0,16-16V88A8,8,0,0,0,213.66,82.34ZM160,51.31,188.69,80H160ZM200,216H56V40h88V88a8,8,0,0,0,8,8h48V216Z"></path>
            </svg>
            {truncateText(maxSelectorWidth, currentDocument.title)}
          </div>
        ) : (
          <div className={`flex items-center max-w-[${maxSelectorWidth}px]`}>
            <svg
              className="mr-1"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 256 256"
            >
              <path d="M80,224a8,8,0,0,1-8,8H56a16,16,0,0,1-16-16V184a8,8,0,0,1,16,0v32H72A8,8,0,0,1,80,224ZM216,88v48a8,8,0,0,1-16,0V96H152a8,8,0,0,1-8-8V40H120a8,8,0,0,1,0-16h32a8,8,0,0,1,5.66,2.34l56,56A8,8,0,0,1,216,88Zm-56-8h28.69L160,51.31ZM80,24H56A16,16,0,0,0,40,40V64a8,8,0,0,0,16,0V40H80a8,8,0,0,0,0-16ZM208,168a8,8,0,0,0-8,8v40h-8a8,8,0,0,0,0,16h8a16,16,0,0,0,16-16V176A8,8,0,0,0,208,168ZM48,152a8,8,0,0,0,8-8V104a8,8,0,0,0-16,0v40A8,8,0,0,0,48,152Zm104,64H112a8,8,0,0,0,0,16h40a8,8,0,0,0,0-16Z"></path>
            </svg>
            {truncateText(maxSelectorWidth, 'No Selection')}
          </div>
        )}
        <ChevronDownIcon className="ml-2 h-6 w-6 text-gray-500 group-hover:text-black" />
      </div>
    </Paper>
  )
})

DocumentsButtonMenuTarget.displayName = 'DocumentsButtonMenuTarget'

const NotificationButtonMenuTarget = forwardRef<
  HTMLDivElement,
  { onClickNotificationsButton: () => void }
>(({ onClickNotificationsButton }, ref) => {
  const { currentData: notifications } = useGetNotificationsQuery(false, {
    pollingInterval: 10000,
  })
  return (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group relative cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      onClick={onClickNotificationsButton}
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] text-gray-500 group-hover:text-black">
        {notifications?.length ? (
          <BellAlertIcon className="!text-red mr-2 h-6 w-6 group-hover:text-black" />
        ) : (
          <BellIcon className="mr-2 h-6 w-6 text-gray-500 group-hover:text-black" />
        )}
        Notifications
        {notifications?.length ?? false ? (
          <CircleDot
            number={notifications?.length ?? 0}
            size="md"
            color="yellow"
            opacity={0.5}
            className="group-hover:opacity-100"
          />
        ) : null}
      </div>
    </Paper>
  )
})
NotificationButtonMenuTarget.displayName = 'NotificationButtonMenuTarget'
const AccountButtonMenuTarget = forwardRef<HTMLDivElement, {}>((props, ref) => {
  return (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      ref={ref}
    >
      <div className="flex items-center justify-center text-[16px] text-gray-500 group-hover:text-black">
        <UserIcon className="mr-2 h-6 w-6 text-gray-500 group-hover:text-black" />
        Account
      </div>
    </Paper>
  )
})
AccountButtonMenuTarget.displayName = 'AccountButtonMenuTarget'
// eslint-disable-next-line complexity
function NavHeader() {
  const {
    PROJECTS_OPTIONS,
    DOCUMENT_DROPDOWN_OPTIONS,
    NOTIFICATION_DROPDOWN_OPTIONS,
    ACCOUNT_DROPDOWN_OPTIONS,
  } = useMenuOptions()
  const { navigateWorkflow } = useNavigateWorkflow()
  const navigate = useNavigate()
  const currentProject = useSelector(selectCurrentProject)

  const { user } = useAuth0()
  const { data: userProfile } = useGetUserProfileQuery(undefined)
  const [pollingInterval, setPollingInterval] = React.useState(5000)
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const [markNotificationsAsRead] = useMarkNotificationsAsReadMutation()
  const [referenceElement] = useState<HTMLElement | null>(null)
  const { currentData: documents } = useGetDocumentStatusByProjectQuery(
    currentProject && currentProject?.id ? currentProject?.id : skipToken,
    {
      pollingInterval,
    }
  )
  const { currentData: notifications } = useGetNotificationsQuery(false, {
    pollingInterval: 10000,
  })

  const anyModalIsCurrentlyOpen = useSelector(selectModal) !== null

  const posthog = usePostHog()

  const onNavigateDocuments = useCallback(() => {
    navigateWorkflow(workflows[0])
    posthog?.capture(POSTHOG.documents_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateRiskReview = useCallback(() => {
    navigateWorkflow(workflows[1])
    posthog?.capture(POSTHOG.risk_review_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateChat = useCallback(() => {
    navigateWorkflow(workflows[2])
    posthog?.capture(POSTHOG.chat_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateComments = useCallback(() => {
    navigateWorkflow(workflows[3])
    posthog?.capture(POSTHOG.comments_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateFilters = useCallback(() => {
    navigateWorkflow(workflows[4])
    posthog?.capture(POSTHOG.clause_filtering_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  const onNavigateSupplementary = useCallback(() => {
    navigateWorkflow(workflows[5])
    posthog?.capture(POSTHOG.supplementary_conditions_workflow_opened, {
      project_uuid: currentProject?.uuid,
    })
  }, [navigateWorkflow, currentProject, posthog])

  useEffect(() => {
    if (!documents) {
      return
    }
    if (
      documents?.every(
        (document) =>
          document.job_status !== 'PENDING' &&
          document.job_status !== 'PROCESSING' &&
          document.job_status !== 'ARCHIVED' &&
          document.job_status !== 'UNARCHIVING'
      )
    ) {
      setPollingInterval(0)
    } else {
      setPollingInterval(5000)
    }
  }, [documents])

  const onBackButton = () => {
    if (currentProject?.uuid) {
      navigate(`/${currentProject?.uuid}/comments`)
    }
  }

  const showCommentViewerBackButtom = useMemo(
    () => pathname.endsWith('commentviewer'),
    [pathname]
  )

  const showBackButton = useMemo(
    // Editors Note: this is a poor pattern but since our app is largely one-page, I think it's reasonable.
    // More than 2 is not reasonable, and if we introduce a new page, please refactor this.
    () => pathname.endsWith('settings') || pathname.endsWith('notifications'),
    [pathname]
  )

  const onOpenHelp = useCallback(() => {
    if (window.productFruits) {
      window.productFruits.api.inAppCenter.open()
      return
    }
    window.$chatwoot.toggle('open')
    dispatch(setHelpOpen(false))
    referenceElement?.click()
  }, [dispatch, referenceElement])

  // Projects

  const { projectId } = useParams()

  const {
    data: projects,
    // eslint-disable-next-line
    isLoading: projectsIsLoading,
    isFetching: projectsIsFetching,
  } = useGetProjectsQuery(undefined)

  useEffect(() => {
    if (projects && projectId && !projectsIsFetching) {
      const projectMatch = projects.find((p) => p.uuid === projectId)
      if (projectMatch) {
        dispatch(setCurrentProject(projectMatch))
      } else {
        navigate('/')
        dispatch(setCurrentDocument(null))
        dispatch(setCurrentProject(null))
      }
    }
  }, [projects, projectId, dispatch, navigate, projectsIsFetching, pathname])

  // Documents
  const [searchParams] = useSearchParams()
  const folderId = useMemo(() => {
    return searchParams.get('folderId')
  }, [searchParams])

  const {
    currentData: documentResponse,
    isLoading: documentsAndFoldersLoading,
  } = useGetDocumentsByProjectQuery(
    currentProject?.id
      ? { projectId: currentProject?.id, folderId: folderId ?? null }
      : skipToken,
    {
      pollingInterval: 10000,
    }
  )

  const projectHasDocuments = (documentResponse?.documents?.length || 0) > 0
  const isNotCommentViewer = !pathname.includes('commentviewer')

  useHotkeys('1', onNavigateDocuments, { enabled: isNotCommentViewer })
  useHotkeys('2', onNavigateRiskReview, {
    enabled: isNotCommentViewer,
  })
  useHotkeys('3', onNavigateChat, {
    enabled: isNotCommentViewer,
  })
  useHotkeys('4', onNavigateComments, {
    enabled: isNotCommentViewer,
  })
  useHotkeys('5', onNavigateFilters, {
    enabled: isNotCommentViewer,
  })
  useHotkeys('6', onNavigateSupplementary, {
    enabled: isNotCommentViewer,
  })

  const { workflowMatch } = useWorkflow()
  const { Icon, title } = workflowMatch as Workflow

  // selectedDocument is used to display the "Selected" text in the dropdown menu.
  const currentDocument = useSelector(selectCurrentDocument)

  useEffect(() => {
    if (currentDocument && documentResponse) {
      const documentTitle = getLabelForCurrentCollectionFromUrl(
        documentResponse.documents ?? [],
        currentDocument.uuid,
        'Document'
      )
      if (documentTitle && documentTitle !== currentDocument.title) {
        dispatch(
          setCurrentDocument({ ...currentDocument, title: documentTitle })
        )
      }
    }
  }, [documentResponse, dispatch, currentDocument])

  const { data: profile } = useGetUserProfileQuery(undefined)
  const [projectsOpened, setProjectsOpened] = useState(false)

  const isHomePage = pathname === '/'
  // When users log out, this redux state is reset. So we check if a user has any projects as well, which
  // indicates that they finished the 2nd step of the T&Cs. Ideally this is a user-level DB field as well so
  // we can persist that state across sessions.

  const userIsActivated = profile?.is_active === true
  const userHasAgreedToTandcs = profile?.agreed_tandcs === true

  // Open the Projects dropdown if the user is on the home page and has agreed to the T&Cs. Also open if the user has no projects.
  // Do not open the dropdown if any other modal is open, or the user isn't activated.
  const shouldOpenProjectsDropdown =
    isHomePage &&
    profile &&
    userIsActivated &&
    !anyModalIsCurrentlyOpen &&
    userHasAgreedToTandcs

  useEffect(() => {
    if (shouldOpenProjectsDropdown) {
      setProjectsOpened(true)
    }
  }, [
    shouldOpenProjectsDropdown,
    projectsOpened,
    pathname,
    projects,
    isHomePage,
    profile,
    userIsActivated,
    anyModalIsCurrentlyOpen,
  ])

  const getLabelForCurrentCollectionFromUrl = (
    collection: any[],
    uuid: string,
    defaultObjectForLabel: string
  ): string | undefined =>
    collection.find((item) => item.uuid === uuid)?.title ??
    `All ${defaultObjectForLabel}s`

  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false)

  // TODO we need to keep track of the current project when jumping across `document/some-id` to `/notifications`.
  // Currently it doesn't have a project to capture so it shows the loader.

  // OnClick method for root Notifications button that clears unread statuses for all children
  const [unreadNotifications, setUnreadNotifications] = useState<string[]>([])
  useEffect(() => {
    if (notifications) {
      const unreadIds = notifications.filter((n) => !n.is_read).map((n) => n.id)
      setUnreadNotifications(unreadIds)
    }
  }, [notifications])

  const onClickNotificationsButton = useCallback(() => {
    if (unreadNotifications.length > 0) {
      markNotificationsAsRead(unreadNotifications)
    }
    setIsNotificationsOpen(!isNotificationsOpen)
  }, [isNotificationsOpen, unreadNotifications, markNotificationsAsRead])

  const useNavigateToPreviousPage = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const HelpButton = () => (
    <Paper
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group cursor-pointer border-2 border-[#ffffff00] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
      onClick={onOpenHelp}
    >
      <div className="flex items-center justify-center text-[16px] text-gray-500 group-hover:text-black">
        <ChatBubbleLeftRightIcon className="mr-2 h-6 w-6 text-gray-500 group-hover:text-black" />
        Help
      </div>
    </Paper>
  )

  // Used to truncate the display text of the Projects and Documents dropdowns
  const [containerRef, containerWidth] = useElementWidth()
  const maxSelectorWidth = useMemo(
    () => containerWidth / 2 - 16,
    [containerWidth]
  )

  const AllAppsButtonMenuTarget = (
    <Paper
      data-testid="All Apps Dropdown Button"
      radius="md"
      p="6px"
      bg="#ffffff00"
      className="group min-w-[230px] cursor-pointer border-2 border-[#dfe2e8] !bg-[#fafafa] transition-colors hover:border-2 hover:border-[#e5e7eb] hover:!bg-[#fff]"
    >
      <div className="flex items-center justify-between text-[16px] font-medium text-gray-900 group-hover:text-black">
        <Tooltip
          label={`Current App: ${title}`}
          offset={12}
          classNames={{ tooltip: '!text-[16px] font-medium' }}
        >
          <div className="flex min-w-0 flex-grow items-center">
            {Icon !== undefined ? (
              <Icon className="ml-1 mr-2 h-5 w-5 flex-shrink-0 text-gray-900 group-hover:text-black" />
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                className="ml-1 mr-1.5"
                fill="currentColor"
                viewBox="0 0 256 256"
              >
                <path d="M96,208a8,8,0,0,1-8,8H39.38A15.4,15.4,0,0,1,24,200.62V192a8,8,0,0,1,16,0v8H88A8,8,0,0,1,96,208Zm64-8H128a8,8,0,0,0,0,16h32a8,8,0,0,0,0-16Zm64-56a8,8,0,0,0-8,8v48H200a8,8,0,0,0,0,16h16.89A15.13,15.13,0,0,0,232,200.89V152A8,8,0,0,0,224,144Zm-8-72H168a8,8,0,0,0,0,16h48v24a8,8,0,0,0,16,0V88A16,16,0,0,0,216,72ZM24,80V56A16,16,0,0,1,40,40H92.69A15.86,15.86,0,0,1,104,44.69l29.66,29.65A8,8,0,0,1,128,88H32A8,8,0,0,1,24,80Zm16-8h68.69l-16-16H40Zm-8,88a8,8,0,0,0,8-8V120a8,8,0,0,0-16,0v32A8,8,0,0,0,32,160Z"></path>
              </svg>
            )}
            <div className="truncate">{title}</div>
          </div>
        </Tooltip>

        <div className="flex flex-shrink-0 items-center">
          <Divider
            orientation="vertical"
            size="2px"
            color="#dfe2e8"
            className="mx-2 h-6"
          />
          <Tooltip
            label={`Click to Open All Apps`}
            offset={12}
            classNames={{ tooltip: '!text-[16px] font-medium' }}
          >
            <div className="flex items-center">
              <Squares2X2Icon className="mr-1 h-6 w-6 flex-shrink-0 text-gray-900 group-hover:text-black" />
              <span className="mr-1">All Apps</span>
            </div>
          </Tooltip>
          {title !== 'Loading...' && (
            <ChevronDownIcon className="ml-1 h-6 w-6 flex-shrink-0 text-gray-900 group-hover:text-black" />
          )}
        </div>
      </div>
    </Paper>
  )

  const BackButton = () => (
    <div className="flex items-center [grid-area:1_/_1_/_1_/_2]">
      <Paper
        radius="md"
        p="6px"
        bg="#ffffff00"
        className="group ml-4 cursor-pointer border-2  border-[#dfe2e8] !bg-[#fafafa] transition-colors hover:border-blue-500 hover:!bg-[#fff]"
        onClick={useNavigateToPreviousPage}
      >
        <div className="mr-2 flex items-center justify-center text-[16px] font-medium !text-black group-hover:!text-blue-900">
          <ChevronLeftIcon className="mr-2 h-6 w-6 text-black group-hover:text-blue-900" />
          {/* TODO make this dynamic and jump to last browser history page */}
          Go Back to Home
        </div>
      </Paper>
    </div>
  )

  return (
    <div className="relative z-50 flex h-14 items-center justify-between border-b border-gray-300 bg-[#f2f5fb] px-1.5">
      {showBackButton ? (
        <BackButton />
      ) : (
        <div className="flex w-1/3 min-w-0 items-center">
          <Container
            className="flex-start flex w-full min-w-0 flex-row"
            ref={containerRef}
          >
            <MenuPopover
              opened={projectsOpened}
              onChange={setProjectsOpened}
              topLabel="Viewing All Projects"
              width={500}
              options={PROJECTS_OPTIONS}
              type="Project"
            >
              <ProjectsButtonMenuTarget maxSelectorWidth={maxSelectorWidth} />
            </MenuPopover>

            <MenuPopover
              topLabel={
                DOCUMENT_DROPDOWN_OPTIONS.length === 1
                  ? 'No Documents Found'
                  : 'Viewing All Documents in This Folder'
              }
              width={500}
              options={DOCUMENT_DROPDOWN_OPTIONS}
              type="Document"
            >
              <DocumentsButtonMenuTarget maxSelectorWidth={maxSelectorWidth} />
            </MenuPopover>
          </Container>
        </div>
      )}

      {/* All Workflows */}
      <div className={'flex w-1/3 items-center justify-center px-2'}>
        {currentProject !== null && workflowMatch.route !== '' ? (
          <AppMenuPopover width={500} menuTarget={AllAppsButtonMenuTarget} />
        ) : null}

        {/* These two elements should never coincide, and thus it's okay for them to share the same container */}
        {showCommentViewerBackButtom && (
          <button
            className={
              'flex items-center rounded border border-gray-400 bg-white px-2 py-1 text-sm transition-colors hover:border-blue-500 hover:bg-sky-50 hover:text-blue-500'
            }
            onClick={onBackButton}
          >
            <ArrowLeftIcon width={'18'} className="mr-1" />
            <span>Back to Comments App</span>
          </button>
        )}
      </div>

      <div className={'flex w-1/3 items-center justify-end px-2'}>
        {userProfile?.feature_flags?.notifications === true && (
          <MenuPopover
            topLabel={
              unreadNotifications.length > 0
                ? 'Viewing All New Notifications'
                : undefined
            }
            width={350}
            options={NOTIFICATION_DROPDOWN_OPTIONS}
            type="Notifications"
            isSearchable={false}
          >
            <NotificationButtonMenuTarget
              onClickNotificationsButton={onClickNotificationsButton}
            />
          </MenuPopover>
        )}

        <HelpButton />

        {user && (
          <MenuPopover
            topLabel="Settings"
            width={250}
            options={ACCOUNT_DROPDOWN_OPTIONS}
            isSearchable={false}
            type="Settings"
          >
            <AccountButtonMenuTarget />
          </MenuPopover>
        )}
      </div>
    </div>
  )
}

export default NavHeader
