import React, { useCallback, useEffect } from 'react'
import { Select } from '@mantine/core'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { Revision } from '../../shared/interfaces/project/document/revision/revision.interface'
import { useUpdateRevisionMutation } from '../../redux/api/api-revisions-slice'
import posthog from 'posthog-js'
import { POSTHOG } from '../../utils/posthog-constants'

interface RevisionStatusBadgeProps {
  revision: Revision | null
  currentProject?: {
    uuid: string
    id: string
  }
  selectedDocument?: {
    uuid: string
    id: string
  }
}

const RevisionStatusBadge: React.FC<RevisionStatusBadgeProps> = ({
  revision,
  currentProject,
  selectedDocument,
}) => {
  const [status, setStatus] = React.useState<string>(
    revision?.revision_status ?? 'NO_STATUS'
  )
  useEffect(() => {
    setStatus(revision?.revision_status ?? 'NO_STATUS')
  }, [revision?.revision_status])

  const [updateRevision] = useUpdateRevisionMutation()

  const statusConfig = {
    NO_STATUS: {
      label: 'Open',
      bgColor: '#DCFCE7',
      textColor: '#047857',
      hoverBg: '#BBF7D0',
      tooltip: 'This comment is still to be addressed',
    },
    APPROVED: {
      label: 'Closed',
      bgColor: '#F3F4F6',
      textColor: '#4B5563',
      hoverBg: '#E5E7EB',
      tooltip: 'This comment has been addressed and has been closed',
    },
    IN_REVIEW: {
      label: 'In Review',
      bgColor: '#FEF3C7',
      textColor: '#92400E',
      hoverBg: '#FDE68A',
      tooltip: 'This comment is in review',
    },
    NOT_APPROVED: {
      label: 'Not Approved',
      bgColor: '#FEE2E2',
      textColor: '#B91C1C',
      hoverBg: '#FECACA',
      tooltip: 'This comment was not approved',
    },
    NEEDS_RECON: {
      label: 'Needs Reconciliation',
      bgColor: '#E0E7FF',
      textColor: '#4338CA',
      hoverBg: '#C7D2FE',
      tooltip: 'This comment needs reconciliation',
    },
    DEFAULT: {
      label: 'Unknown Status',
      bgColor: '#E5E7EB',
      textColor: '#6B7280',
      hoverBg: '#D1D5DB',
      tooltip: 'Unknown status',
    },
  } as const

  const updateRevisionStatus = useCallback(
    (newStatus: string) => {
      if (!revision?.id) return

      const mappedSegmentQuads = revision?.segments?.map((segment) => ({
        ...segment,
        quads: segment?.quads?.map((quad) => ({
          ...quad,
          page: revision?.page,
        })),
      }))

      updateRevision({
        id: revision.id,
        content: {
          ...revision,
          document: selectedDocument ? selectedDocument.id : undefined,
          documents: selectedDocument ? [selectedDocument.id] : undefined,
          project: currentProject?.id,
          parent: revision,
          previous_status: revision.revision_status,
          revision_status: newStatus,
          segments: mappedSegmentQuads,
        },
      })

      posthog.capture(POSTHOG.revision_status_changed, {
        project_uuid: currentProject?.uuid,
        document_uuid: selectedDocument?.uuid,
        revision_id: revision.id,
        revision_status: newStatus,
        workflow: 'document_viewer',
      })
    },
    [currentProject, selectedDocument, revision, updateRevision]
  )

  const handleStatusChange = useCallback(
    (newStatus: string | null) => {
      if (newStatus) {
        setStatus(newStatus)
        updateRevisionStatus(newStatus)
      }
    },
    [updateRevisionStatus]
  )

  const currentConfig =
    statusConfig[status as keyof typeof statusConfig] || statusConfig.DEFAULT

  const statusOptions = [
    { value: 'NO_STATUS', label: 'Open' },
    { value: 'APPROVED', label: 'Closed' },
    { value: 'IN_REVIEW', label: 'In Review' },
    { value: 'NOT_APPROVED', label: 'Not Approved' },
    { value: 'NEEDS_RECON', label: 'Needs Reconciliation' },
  ]

  return (
    <Select
      data-testid="revision-status-select"
      value={status}
      onChange={handleStatusChange}
      data={statusOptions}
      rightSection={<ChevronDownIcon className="h-3 w-3" />}
      styles={(theme) => ({
        input: {
          cursor: 'pointer',
          background: currentConfig.bgColor,
          color: currentConfig.textColor,
          border: 'none',
          padding: '0.125rem 0.375rem',
          fontSize: '0.75rem',
          lineHeight: '1rem',
          fontWeight: 500,
          minHeight: 'unset',
          height: 'unset',
          width: 'auto',
          minWidth: 'max-content',
          '&:hover': {
            background: currentConfig.hoverBg,
          },
          borderRadius: '9999px',
        },
        rightSection: {
          pointerEvents: 'none',
          color: 'currentColor',
        },
        dropdown: {
          border: '1px solid #E5E7EB',
          boxShadow: '0 4px 6px -1px rgb(0 0 0 / 0.1)',
        },
        item: {
          fontSize: '0.875rem',
          padding: '0.5rem 1rem',
          '&[data-selected]': {
            backgroundColor: theme.colors.blue[0],
            color: 'inherit',
          },
          '&[data-hovered]': {
            backgroundColor: theme.colors.gray[0],
          },
        },
      })}
      classNames={{
        root: 'inline-block',
        input: 'inline-flex items-center',
      }}
    />
  )
}

export default RevisionStatusBadge
