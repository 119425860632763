import React, { useCallback } from 'react'
import {
  selectCurrentProject,
  setModal,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../../utils/posthog-constants'

interface AIChatFeedbackButtonProps {
  message: string
  type: 'Good' | 'Bad'
  sessionUuid: string | null | undefined
  query: string
}

const AIChatFeedbackButton: React.FC<AIChatFeedbackButtonProps> = ({
  message,
  type,
  sessionUuid,
  query,
}) => {
  const posthog = usePostHog()
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)

  const onPositiveFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.chat_feedback_thumbs_up, {
      project_uuid: currentProject?.uuid,
      chat_session_uuid: sessionUuid,
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: { positive: true, query, answer: message },
      })
    )
    // eslint-disable-next-line
  }, [dispatch, query, message])

  const onNegativeFeedback = useCallback(() => {
    posthog?.capture(POSTHOG.chat_feedback_thumbs_down, {
      project_uuid: currentProject?.uuid,
      chat_session_uuid: sessionUuid,
    })
    dispatch(
      setModal({
        modal: 'AI_CHAT_FEEDBACK',
        data: { positive: false, query, answer: message },
      })
    )
    // eslint-disable-next-line
  }, [dispatch, query, message])

  const onClickFeedback = useCallback(() => {
    if (type === 'Good') {
      onPositiveFeedback()
    } else {
      onNegativeFeedback()
    }
    // eslint-disable-next-line
  }, [
    onPositiveFeedback,
    onNegativeFeedback,
    posthog,
    currentProject,
    sessionUuid,
    type,
  ])

  return (
    <button
      onClick={onClickFeedback}
      className={`flex-1 rounded bg-gray-100 px-3 py-0.5 ${
        type === 'Good' ? 'hover:bg-green-200' : 'hover:bg-red-200'
      }`}
    >
      {type}
    </button>
  )
}

export default AIChatFeedbackButton
