import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Definition } from '../../shared/interfaces/definition/definition.interface'
import { selectCurrentProject } from '../../redux/application-slice'
import {
  useGetDefinitionsByQueryQuery,
  useGetDocumentsListByProjectQuery,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import SidebarDefinitionButton from './sidebar-definition-button'
import {
  selectDefinitionQuery,
  setDefinitionQuery,
} from '../../redux/search-slice'
import DocumentListboxMulti from '../document-listbox/document-listbox-multi'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { CloseButton, Group, Text } from '@mantine/core'
import { setDocumentViewerSidebarOpen } from '../../redux/viewer-slice'
const SidebarDefinitions = () => {
  const currentProject = useSelector(selectCurrentProject)
  const defQuery = useSelector(selectDefinitionQuery)

  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )

  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[] | null
  >(null)

  const { data: definitionsData, isFetching: definitionsFetching } =
    useGetDefinitionsByQueryQuery(
      selectedDocuments
        ? {
          documentIds: selectedDocuments.map((d) => d.uuid),
        }
        : currentProject?.id
          ? { projectId: currentProject?.id }
          : skipToken
    )
  const dispatch = useDispatch()

  const onChangeDefinitionQuery = useCallback(
    (e) => {
      dispatch(setDefinitionQuery(e.target.value))
    },
    [dispatch]
  )

  const setProjectDocumentFilter = useCallback(
    (projectDocuments: ProjectDocumentMetadata[]) => {
      setSelectedDocuments(projectDocuments)
    },
    []
  )

  const onClose = () => {
    dispatch(setDocumentViewerSidebarOpen(null))
  }

  return (
    <div className="flex flex-col space-y-2 p-4 overflow-y-auto">
      <Group justify="space-between">
        <Text fz="md" fw={500}>
          Definitions
        </Text>
        <CloseButton onClick={onClose} />
      </Group>

      <input
        type="text"
        name="search"
        id="search"
        value={defQuery}
        className="block w-full resize-none rounded-md border-gray-300 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
        placeholder={'Search definitions...'}
        onChange={onChangeDefinitionQuery}
      />
      <DocumentListboxMulti
        documents={documents ?? []}
        setSelectedDocuments={setProjectDocumentFilter}
        selectedDocuments={selectedDocuments ?? []}
      />
      <div
        className={
          'max-h-[30vh] min-h-0 flex-1 overflow-auto overflow-y-scroll'
        }
      >
        <div className={'space-y overflow-y-auto'}>
          {definitionsData &&
            Object.values(definitionsData as Definition[]).filter(
              (def: Definition) =>
                def.text.toLowerCase().includes(defQuery ? defQuery.toLowerCase() : ' ')
            ).length === 0 && (
              <div className={'mt-3 text-center text-xs text-gray-400'}>
                No matching definitions
              </div>
            )}

          {definitionsFetching ? (
            <div className={'mt-5 text-center text-xs text-gray-400'}>
              Loading...
            </div>
          ) : (
            defQuery &&
            definitionsData
              ?.filter((def: Definition) =>
                def.text.toLowerCase().includes(defQuery ? defQuery.toLowerCase() : '')
              )
              .slice(0, 10)
              .map((definition: Definition) => (
                <SidebarDefinitionButton
                  key={`definition_${definition.id}`}
                  definition={definition}
                  documents={documents ?? []}
                />
              ))
          )}
        </div>
      </div>
    </div>
  )
}
export default SidebarDefinitions
