import React, { useCallback, useContext } from 'react'
import { useSelector } from 'react-redux'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import { Route, useBackButton } from '../../hooks/use-back-button'
import { selectCurrentDocument } from '../../redux/application-slice'
import { useWorkflow } from '../../hooks/use-workflow'
import { POSTHOG } from '../../utils/posthog-constants'
import posthog from 'posthog-js'
import { useParams } from 'react-router-dom'

interface SidebarTOCButtonProps {
  toc
  currentPage: number
}

const SidebarTOCButton = ({ toc, currentPage }: SidebarTOCButtonProps) => {
  const documentViewerContext = useContext(DocumentViewerContext)
  const { addToNavigationHistoryAndNavigate } = useBackButton()
  const currentDocument = useSelector(selectCurrentDocument)
  const { workflowMatch } = useWorkflow()
  const { projectId } = useParams<{ projectId: string }>()

  const onTOCClick = useCallback(() => {
    posthog.capture(POSTHOG.toc_item_clicked, {
      projectId: projectId ?? '',
      documentId: currentDocument?.uuid ?? '',
      title: toc.title,
    })
    const page = documentViewerContext?.documentViewer?.getCurrentPage()
    if (!page || !currentDocument?.uuid || !workflowMatch.route) {
      return null
    }
    addToNavigationHistoryAndNavigate(
      workflowMatch.route as Route,
      currentDocument?.uuid,
      toc.page
    )
    // eslint-disable-next-line
  }, [
    addToNavigationHistoryAndNavigate,
    currentDocument?.uuid,
    documentViewerContext?.documentViewer,
    toc.page,
    workflowMatch.route,
  ])
  return (
    <button
      key={`${toc.title}${toc.page}`}
      className={`flex w-full cursor-pointer justify-between space-x-4 px-1 rounded py-1 hover:bg-gray-200 ${currentPage === toc.page ? 'bg-gray-200' : ''
        }`}
      onClick={onTOCClick}
    >
      <div className={'overflow-hidden text-ellipsis whitespace-nowrap'}>
        {toc.title}
      </div>
      <div className={'flex-shrink-0'}>
        <span>{toc.page}</span>
      </div>
    </button>
  )
}

export default SidebarTOCButton
