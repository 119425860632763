import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useGetFileExportQuery } from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'

const FileExport = () => {
  const { fileExportId } = useParams()
  const { data: fileExport } = useGetFileExportQuery(fileExportId ?? skipToken)
  const navigate = useNavigate()

  useEffect(() => {
    if (!fileExport) {
      return
    }
    const url = fileExport.export_file
    window.open(url, '_blank')
    navigate('/')
  }, [navigate, fileExport])
  return <div></div>
}

export default FileExport
