import React, { useCallback, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'
import { Tooltip } from '@mantine/core'
import { useLeaveProjectMutation } from '../../redux/api-slice'
import { toast } from 'react-toastify'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import { setCurrentProject, setModal } from '../../redux/application-slice'
import { useDispatch } from 'react-redux'

interface ProjectSettingsActionButtonsProps {
  canDelete: boolean
  onDeleteClick: () => void
  projectId?: string
}

interface LeaveConfirmationContentProps {
  onLeave: () => void
  onCancel: () => void
}

const LeaveConfirmationContent = ({
  onLeave,
  onCancel,
}: LeaveConfirmationContentProps) => (
  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
    <div className="sm:flex sm:items-start">
      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0 sm:h-10 sm:w-10">
        <ExclamationTriangleIcon
          className="h-6 w-6 text-yellow-600"
          aria-hidden="true"
        />
      </div>
      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
        <Dialog.Title
          as="h3"
          className="text-base font-semibold leading-6 text-gray-900"
        >
          Leave project
        </Dialog.Title>
        <p className="mt-2 text-sm text-gray-500">
          Are you sure you want to leave this project? You will lose access to
          all project content and will need to be invited back to regain access.
        </p>
      </div>
    </div>
  </div>
)

const DialogActions: React.FC<LeaveConfirmationContentProps> = ({
  onLeave,
  onCancel,
}) => (
  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
    <button
      type="button"
      className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:ml-3 sm:w-auto"
      onClick={onLeave}
    >
      Leave
    </button>
    <button
      type="button"
      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
      onClick={onCancel}
    >
      Cancel
    </button>
  </div>
)

const ActionButtons: React.FC<{
  canDelete: boolean
  onDeleteClick: () => void
  onLeaveClick: () => void
}> = ({ canDelete, onDeleteClick, onLeaveClick }) => (
  <div className="flex space-x-2">
    <Tooltip
      label="You do not have permissions to perform this action"
      disabled={canDelete}
      position="top"
    >
      <button
        type="button"
        disabled={!canDelete}
        onClick={onDeleteClick}
        className={`inline-flex justify-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset
      ${
        canDelete
          ? 'bg-red-600 text-white ring-red-600 hover:bg-red-500'
          : 'bg-gray-100 text-gray-400 ring-gray-300'
      }`}
      >
        Delete Project
      </button>
    </Tooltip>

    <button
      type="button"
      onClick={onLeaveClick}
      className="inline-flex justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-blue-600 hover:bg-blue-500"
    >
      Leave Project
    </button>
  </div>
)

const ProjectSettingsActionButtons: React.FC<
  ProjectSettingsActionButtonsProps
> = ({ canDelete, onDeleteClick, projectId }) => {
  const [leaveProject] = useLeaveProjectMutation()
  const [showLeaveConfirmation, setShowLeaveConfirmation] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  interface ApiError {
    status: number
    data: {
      detail: string
    }
  }

  const onLeaveClick = useCallback(async () => {
    await leaveProject(projectId)
      .unwrap()
      .then(() => {
        navigate('/')
        dispatch(setCurrentProject(null))
        setShowLeaveConfirmation(false)
        dispatch(setModal(null))
        toast.success('Successfully left the project')
      })
      .catch((error: unknown) => {
        if (error && typeof error === 'object' && 'data' in error) {
          const err = error as ApiError
          toast.warning(err.data.detail) // in case the user is a project admin, the api will raise that detail in its error message
        } else {
          toast.error('Failed to leave the project. Please try again.')
        }
      })
  }, [leaveProject, projectId, dispatch, navigate])

  const onButtonsLeaveClick = () => setShowLeaveConfirmation(true)

  return (
    <>
      <ActionButtons
        canDelete={canDelete}
        onDeleteClick={onDeleteClick}
        onLeaveClick={onButtonsLeaveClick}
      />

      <Transition.Root show={showLeaveConfirmation} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          onClose={() => setShowLeaveConfirmation(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <LeaveConfirmationContent
                    onLeave={onLeaveClick}
                    onCancel={() => setShowLeaveConfirmation(false)}
                  />
                  <DialogActions
                    onLeave={onLeaveClick}
                    onCancel={() => setShowLeaveConfirmation(false)}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default ProjectSettingsActionButtons
