import React, { useCallback, useMemo, useState } from 'react'
import { ProjectDocumentMetadata } from '../../../shared/interfaces/project/document/document.interface'
import { useGetDocumentsListByProjectQuery } from '../../../redux/api-slice'
import {
  selectCurrentCommentDocuments,
  selectCurrentProject,
  setModal,
} from '../../../redux/application-slice'
import { useDispatch, useSelector } from 'react-redux'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import { MODAL_TYPES } from '../../modals/modal-controller'
import DocumentListboxMulti from '../../document-listbox/document-listbox-multi'
import { skipToken } from '@reduxjs/toolkit/query'
import { usePopper } from 'react-popper'
import { Popover } from '@headlessui/react'
import {
  EllipsisVerticalIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom'
import CustomLabelFilter from './custom-label-filter'
import {
  selectCommentWorkflowFilterState,
  selectCommentWorkflowFilterStateQueryParams,
  setCommentWorkflowFilterState,
} from '../../../redux/workflow-slice'
import RevisionAuthorFilter from './revision-author-filter'
import RevisionStatusFilter from './revision-status-filter'
import RevisionDateFilter from './revision-date-filter'
import { Tooltip } from 'react-tooltip'

interface CommentTableHeaderProps {
  revisionsData: Revision[]
}

const ExportPopoverButton = ({
  documentProcessingCount,
  documents,
  onClickReconcileComments,
  onExcelExport,
  setExportButtonRef,
  setExportPanelRef,
  exportStyles,
  exportAttributes,
}: {
  documentProcessingCount: number
  documents: ProjectDocumentMetadata[]
  onClickReconcileComments: () => void
  onExcelExport: () => void
  setExportButtonRef: (ref: HTMLButtonElement | null) => void
  setExportPanelRef: (ref: HTMLDivElement | null) => void
  exportStyles: Record<string, any>
  exportAttributes: Record<string, any>
}) => {
  return (
    <Popover className="relative flex items-center justify-center">
      <Popover.Button
        className={'rounded hover:bg-gray-200'}
        tabIndex={-1}
        disabled={documentProcessingCount > 0 || !documents?.length}
        data-tooltip-id={
          documentProcessingCount === 0 && documents?.length
            ? ''
            : `export-comments-id`
        }
        data-tooltip-content="Export will be available when document processing is complete"
        ref={setExportButtonRef}
      >
        <div className={'p-1'}>
          <EllipsisVerticalIcon width={24} />
        </div>
      </Popover.Button>

      <Popover.Panel
        ref={setExportPanelRef}
        style={exportStyles.popper}
        {...exportAttributes.popper}
        className="z-50 rounded border bg-white shadow"
      >
        <button
          className="w-36 px-4 py-2 text-left hover:bg-gray-100"
          onClick={onClickReconcileComments}
        >
          Reconcile comments
        </button>
        <button
          className="w-36 px-4 py-2 text-left hover:bg-gray-100"
          onClick={onExcelExport}
        >
          Export to Excel
        </button>
      </Popover.Panel>
    </Popover>
  )
}

const CommentTableHeader: React.FC<CommentTableHeaderProps> = ({
  revisionsData,
}) => {
  const currentProject = useSelector(selectCurrentProject)
  const currentCommentDocuments = useSelector(selectCurrentCommentDocuments)
  const revisionParams = useSelector(
    selectCommentWorkflowFilterStateQueryParams
  )
  const posthog = usePostHog()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject
      ? { projectId: currentProject?.id, latest_version_only: true }
      : skipToken
  )
  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  const commentWorkflowFilterState = useSelector(
    selectCommentWorkflowFilterState
  )

  const [exportButtonRef, setExportButtonRef] =
    useState<HTMLButtonElement | null>(null)
  const [exportPanelRef, setExportPanelRef] = useState<HTMLDivElement | null>(
    null
  )
  const { styles: exportStyles, attributes: exportAttributes } = usePopper(
    exportButtonRef,
    exportPanelRef
  )

  const onExcelExport = useCallback(() => {
    posthog?.capture(POSTHOG.download_comment_table, {
      project_uuid: currentProject?.uuid,
    })
    dispatch(setModal({ modal: MODAL_TYPES.EXPORT_OPTIONS, revisionParams }))
  }, [revisionParams, currentProject?.uuid, posthog, dispatch])

  const onChangeSearchQuery = useCallback(
    (e) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...commentWorkflowFilterState,
          searchQuery: e.target.value,
        })
      )
    },
    [dispatch, commentWorkflowFilterState]
  )

  const onClickReconcileComments = useCallback(() => {
    if (!currentProject?.uuid) {
      return
    }
    navigate(
      `/${currentProject?.uuid}/commentviewer${currentCommentDocuments?.length === 1
        ? `?documentId=${currentCommentDocuments[0]?.uuid}`
        : ''
      }`
    )
  }, [currentProject?.uuid, navigate, currentCommentDocuments])

  const setDocumentsFilter = useCallback(
    (documents: ProjectDocumentMetadata[]) => {
      dispatch(
        setCommentWorkflowFilterState({
          ...commentWorkflowFilterState,
          documentsFilter: documents,
        })
      )
    },
    [dispatch, commentWorkflowFilterState]
  )

  return (
    <>
      <div
        className={
          'flex w-full flex-none flex-col space-y-2 border-y border-gray-100 p-2 text-xs'
        }
      >
        <div className="grid min-w-0 grid-cols-[1fr_32px] grid-rows-[1fr] gap-x-[8px] gap-y-[0px]">
          {/* Left side - Input boxes container */}
          <div
            className={
              'flex min-w-0 flex-1 flex-col gap-2 [grid-area:1_/_1_/_2_/_2]'
            }
          >
            <div className="flex min-w-0 max-w-[1000px] gap-2">
              <div className={'relative flex min-w-0 flex-1 items-center'}>
                <MagnifyingGlassIcon className="absolute left-1.5 w-4 text-gray-500" />
                <input
                  className="w-full overflow-hidden rounded-md border-0 bg-white py-0.5 pl-6 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  placeholder="Search revisions and comments"
                  type={'text'}
                  onChange={onChangeSearchQuery}
                  value={commentWorkflowFilterState.searchQuery}
                />
              </div>
              {documents && (
                <div className="min-w-0 flex-1">
                  <DocumentListboxMulti
                    documents={documents}
                    selectedDocuments={
                      commentWorkflowFilterState.documentsFilter ?? []
                    }
                    setSelectedDocuments={setDocumentsFilter}
                  />
                </div>
              )}
            </div>
            <div className="flex min-w-0 max-w-[1000px] gap-2">
              <div className="min-w-0 flex-1">
                <CustomLabelFilter />
              </div>
              <div className="min-w-0 flex-1">
                <RevisionAuthorFilter revisionsData={revisionsData} />
              </div>
              <div className="min-w-0 flex-1">
                <RevisionStatusFilter />
              </div>
            </div>
            <RevisionDateFilter />
          </div>

          {/* Right side - Export button */}
          <div className="flex items-start [grid-area:1_/_2_/_2_/_3]">
            <ExportPopoverButton
              documentProcessingCount={documentProcessingCount}
              documents={documents ?? []}
              onClickReconcileComments={onClickReconcileComments}
              onExcelExport={onExcelExport}
              setExportButtonRef={setExportButtonRef}
              setExportPanelRef={setExportPanelRef}
              exportStyles={exportStyles}
              exportAttributes={exportAttributes}
            />
          </div>
        </div>
      </div>
      <Tooltip id="export-comments-id" />
    </>
  )
}

export default CommentTableHeader
