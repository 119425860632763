import React, {
  useCallback,
  useEffect,
  useState,
  useContext,
} from 'react'
import {
  selectSearchPage,
  selectSearchQuery,
  selectSearchResults,
  setFilterDocumentUuids,
  setSearchPage,
  setSearchQuery,
  setSelectedResult,
} from '../../redux/search-slice'
import { useDispatch, useSelector } from 'react-redux'
import { skipToken } from '@reduxjs/toolkit/query'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../redux/application-slice'
import {
  selectCurrentPage,
  selectIsSearching,
  setDocumentViewerSidebarOpen,
} from '../../redux/viewer-slice'
import TextareaAutosize from 'react-textarea-autosize'
import { useHotkeys } from 'react-hotkeys-hook'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { DocumentViewerContext } from '../../contexts/document-viewer-instance-context'
import DocumentListboxMulti from '../document-listbox/document-listbox-multi'
import { Hit } from '../../shared/interfaces/search-results.interface'
import { usePostHog } from 'posthog-js/react'
import { POSTHOG } from '../../utils/posthog-constants'
import { Route, useBackButton } from '../../hooks/use-back-button'
import { useLocation } from 'react-router-dom'
import { useGetDocumentsListByProjectQuery } from '../../redux/api-slice'
import { Group, Text, CloseButton } from '@mantine/core'

interface TypesearchSearchProps {
  query?: string
  open?: boolean
}

const TypesenseSearch: React.FC<TypesearchSearchProps> = () => {
  const currentProject = useSelector(selectCurrentProject)
  const searchQuery = useSelector(selectSearchQuery)
  const currentPage = useSelector(selectCurrentPage)
  const dispatch = useDispatch()
  const { data: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken
  )
  const searchResults = useSelector(selectSearchResults)
  const [selectedDocuments, setSelectedDocuments] = useState<
    ProjectDocumentMetadata[] | null
  >(null)
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const [highlightedResult, setHighlightedResult] = useState<Hit | null>(null)
  const documentViewerContext = useContext(DocumentViewerContext)
  const currentDocument = useSelector(selectCurrentDocument)
  const { documentViewer } = documentViewerContext
  const isSearching = useSelector(selectIsSearching)
  const posthog = usePostHog()
  const searchPage = useSelector(selectSearchPage)
  const { addToNavigationHistoryAndNavigate } = useBackButton()
  const { pathname } = useLocation()

  const onResultClick = useCallback(
    (result: Hit) => {
      posthog?.capture(POSTHOG.document_typesense_result_clicked, {
        query: searchQuery,
        document_uuid: currentDocument?.uuid,
        result_page: result.document.page,
      })

      dispatch(setSelectedResult(result))
      const route = pathname.split('/')[2]
      if (!route || !result.document.document_uuid) {
        return
      }
      addToNavigationHistoryAndNavigate(
        route as Route,
        result.document.document_uuid,
        result.document.page
      )
    },
    [
      addToNavigationHistoryAndNavigate,
      currentDocument?.uuid,
      dispatch,
      pathname,
      posthog,
      searchQuery,
    ]
  )

  useEffect(() => {
    if (searchPage !== 1) {
      return
    }
    setCurrentIndex(0)
    if (!searchResults || !searchResults?.hits?.length) {
      setHighlightedResult(null)
    } else {
      setHighlightedResult(searchResults?.hits[0])
      onResultClick(searchResults?.hits[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchResults, searchPage])

  const onSearchResult = useCallback(
    (result) => {
      const currentSearchResults = documentViewer?.getPageSearchResults()
      if (!currentSearchResults) {
        documentViewer?.displaySearchResult(result)
      } else {
        documentViewer?.displayAdditionalSearchResult(result)
      }
    },
    [documentViewer]
  )

  const onTriggerSearch = useCallback(
    (isReverse = false) => {
      if (!documentViewer) {
        return
      }
      let mode =
        window.Core.Search.Mode.HIGHLIGHT | window.Core.Search.Mode.PAGE_STOP
      if (isReverse) {
        mode |= window.Core.Search.Mode.SEARCH_UP
      }

      const searchOptions: {
        fullSearch?: boolean
        onResult?: (...params: any[]) => any
        onPageEnd?: (...params: any[]) => any
        onDocumentEnd?: (...params: any[]) => any
        onError?: (...params: any[]) => any
        startPage?: number
        endPage?: number
      } = {
        fullSearch: true,
        onResult: onSearchResult,
        startPage: currentPage,
        endPage: currentPage,
      }
      documentViewer.textSearchInit(searchQuery, mode, searchOptions)
    },
    [currentPage, documentViewer, onSearchResult, searchQuery]
  )

  useEffect(() => {
    documentViewer?.clearSearchResults()
  }, [documentViewer, searchQuery])

  useEffect(() => {
    if (!documentViewer) {
      return
    }
    onTriggerSearch()
  }, [documentViewer, onTriggerSearch])

  const onSelectNextResult = useCallback(() => {
    if (!searchResults?.hits) {
      return
    }
    const index = searchResults?.hits
      .map((item) => item.document.id)
      .indexOf(highlightedResult?.document?.id ?? '')
    if (index < searchResults?.hits.length - 1) {
      setHighlightedResult(searchResults?.hits[index + 1])
      setCurrentIndex(index + 1)
      onResultClick(searchResults?.hits[index + 1])
    }
    if (index === searchResults?.hits.length - 1) {
      dispatch(setSearchPage(searchPage + 1))
    }
    onTriggerSearch()
  }, [
    dispatch,
    highlightedResult?.document?.id,
    onResultClick,
    searchPage,
    searchResults?.hits,
    onTriggerSearch,
  ])

  const onSelectPreviousResult = useCallback(() => {
    if (!searchResults?.hits) {
      return
    }
    const index = searchResults?.hits
      .map((item) => item.document.id)
      .indexOf(highlightedResult?.document?.id ?? '')
    if (index > 0) {
      setCurrentIndex(index - 1)
      setHighlightedResult(searchResults?.hits[index - 1])
      onResultClick(searchResults?.hits[index - 1])
    }
    onTriggerSearch()
  }, [
    highlightedResult?.document?.id,
    onResultClick,
    onTriggerSearch,
    searchResults?.hits,
  ])

  useHotkeys('down', onSelectNextResult, { preventDefault: true })
  useHotkeys('up', onSelectPreviousResult, { preventDefault: true })
  useHotkeys('tab', onSelectNextResult, { preventDefault: true })
  useHotkeys('enter', onSelectNextResult, { preventDefault: true })
  useHotkeys('shift+enter', onSelectPreviousResult, { preventDefault: true })

  const setProjectDocumentFilter = useCallback(
    (projectDocuments: ProjectDocumentMetadata[]) => {
      setSelectedDocuments(projectDocuments)
      if (!projectDocuments?.length) {
        dispatch(setFilterDocumentUuids([]))
        return
      }
      dispatch(setFilterDocumentUuids(projectDocuments.map((d) => d.uuid)))
    },
    [dispatch]
  )

  const onSearchKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.key === 'Enter' && !e.shiftKey) {
        e.preventDefault()
        onSelectNextResult()
      } else if (e.key === 'Enter' && e.shiftKey) {
        e.preventDefault()
        onSelectPreviousResult()
      }
    },
    [onSelectNextResult, onSelectPreviousResult]
  )

  const onChangeSearch = useCallback(
    (e) => {
      dispatch(setSearchQuery(e.target.value))
      posthog?.capture(POSTHOG.document_typesense_search, {
        project_uuid: currentProject?.uuid ?? '',
      })
    },
    // eslint-disable-next-line
    [dispatch, currentProject?.uuid, posthog]
  )

  const onClose = () => {
    dispatch(setDocumentViewerSidebarOpen(null))
  }

  return (
    <div
      className={
        'flex h-full min-h-0 min-w-0 flex-grow flex-col space-y-2 overflow-visible rounded p-4'
      }
    >
      <Group justify="space-between">
        <Text fz="md" fw={500}>
          Search
        </Text>
        <CloseButton onClick={onClose} />
      </Group>

      <div className="relative flex items-center">
        <TextareaAutosize
          name="search"
          id="search"
          className="block w-full resize-none rounded-md border-gray-300 p-2 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          placeholder={'Enter a search term'}
          value={searchQuery}
          onKeyDown={onSearchKeyDown}
          onChange={onChangeSearch}
        />
        <div className={'absolute right-2 top-1/2 -translate-y-1/2 text-center text-xs text-gray-400'}>
          {searchQuery && searchResults?.found && (
            <>
              {currentIndex + 1} / {searchResults.found} items
            </>
          )}
        </div>
      </div>
      <DocumentListboxMulti
        documents={documents}
        setSelectedDocuments={setProjectDocumentFilter}
        selectedDocuments={selectedDocuments ?? []}
        customWidth={'w-96'}
      />
      <div className={'flex h-full flex-1 flex-col overflow-hidden'}>

        <div className="flex h-full flex-col space-y-2 focus-visible:outline-0">
          <div className="flex justify-between items-stretch space-x-2 text-xs text-gray-900">
            <button
              disabled={isSearching || !searchQuery}
              onClick={onSelectPreviousResult}
              className="flex-1 rounded py-1 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <div>
                Previous
              </div>
            </button>
            <button
              disabled={isSearching || !searchQuery}
              onClick={onSelectNextResult}
              className="flex-1 rounded py-1 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <div>
                Next
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TypesenseSearch
