import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  useGetDocumentsListByProjectQuery,
  useUnarchiveProjectMutation,
} from '../../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/dist/query'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectCurrentDocument,
  selectCurrentProject,
  selectSupplementaryConditionsAreProcessing,
  setSupplementaryConditionsAreProcessing,
} from '../../redux/application-slice'
import dayjs from 'dayjs'
import { ProjectDocumentMetadata } from '../../shared/interfaces/project/document/document.interface'
import { useNavigate } from 'react-router-dom'
import {
  useGetProjectRiskStatsQuery,
  useGetProjectRisksProcessingStatusQuery,
} from '../../redux/api/project-risk-api-slice'
import { RiskRanking } from '../../shared/interfaces/project/risk/risk-inteface'
import {
  SupplementaryConditionsBanner,
  UnarchivingBanner,
  ProcessingBanner,
  CompletedBanner,
  RiskAnalysingBanner,
} from '../banners/specific-banners'

const ProjectUploadStatus: React.FC = () => {
  const dispatch = useDispatch()
  const currentProject = useSelector(selectCurrentProject)
  const currentDocument = useSelector(selectCurrentDocument)
  const supplementaryConditionsAreProcessing = useSelector(
    selectSupplementaryConditionsAreProcessing
  )
  const [timeEstimate, setTimeEstimate] = useState<number | null>(100)
  const documentProcessingRef = useRef<{ [documentId: string]: boolean }>({})
  const navigate = useNavigate()
  const [unarchiveProject] = useUnarchiveProjectMutation()

  const { currentData: projectRiskStats, refetch: projectRiskRefetch } =
    useGetProjectRiskStatsQuery(currentProject?.uuid ?? skipToken)

  const { currentData: riskPipelineStats } =
    useGetProjectRisksProcessingStatusQuery(currentProject?.uuid ?? skipToken, {
      pollingInterval: 2000,
    })

  const { currentData: documents } = useGetDocumentsListByProjectQuery(
    currentProject ? { projectId: currentProject?.id } : skipToken,
    {
      pollingInterval: 5000,
    }
  )

  // once documents are loaded, check if any are in review, and post to redux
  useEffect(() => {
    if (!documents) return

    const isProcessing = documents.some(
      (document) => document.job_status === 'IN_REVIEW'
    )

    dispatch(setSupplementaryConditionsAreProcessing(isProcessing))
  }, [documents, dispatch])

  useEffect(() => {
    if (!documents) {
      return
    }
    projectRiskRefetch()
  }, [documents, currentProject, projectRiskRefetch])

  useEffect(() => {
    if (!documents) {
      return
    }
    if (
      documents?.every(
        (document) =>
          document.job_status !== 'PENDING' &&
          document.job_status !== 'PROCESSING' &&
          document.job_status !== 'ARCHIVED' &&
          document.job_status !== 'UNARCHIVING'
      )
    ) {
      setTimeEstimate(null)
    }
    const processingDocuments = documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    )
    if (documents.find((document) => document.total_pages === null)) {
      setTimeEstimate(null)
      return
    }
    const newDocumentsToAdd: ProjectDocumentMetadata[] = []
    for (const processingDocument of processingDocuments) {
      if (!documentProcessingRef.current[processingDocument.id]) {
        newDocumentsToAdd.push(processingDocument)
        documentProcessingRef.current[processingDocument.id] = true
      }
    }
    if (newDocumentsToAdd.length === 0) {
      return
    }
    setTimeEstimate(
      (t) =>
        (t ?? 0) +
        newDocumentsToAdd.reduce((acc, cur) => {
          const difference = dayjs().diff(dayjs(cur.date_created), 'second')
          const estimate = Math.round(acc + cur.total_pages) - difference
          if (estimate < 0) {
            return 0
          }
          return estimate
        }, 0)
    )
  }, [documents])

  const documentProcessingCount = useMemo(() => {
    if (!documents) {
      return 0
    }

    return documents.filter(
      (document) =>
        document.job_status === 'PROCESSING' ||
        document.job_status === 'PENDING'
    ).length
  }, [documents])

  const fancyTimeFormat = (duration: number) => {
    if (duration === 0) {
      return 'Almost there...'
    }
    // Hours, minutes and seconds
    // Double NOT operator gets the floor of a positive integer faster than Math.floor
    const hrs = ~~(duration / 3600)
    const mins = ~~((duration % 3600) / 60)
    const secs = ~~duration % 60

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = ''

    if (hrs > 0) {
      ret += `${hrs}:${mins < 10 ? '0' : ''}`
    }

    ret += `${mins}:${secs < 10 ? '0' : ''}`
    ret += `${secs}`

    return ret
  }

  useEffect(() => {
    if ((timeEstimate ?? 0) < 0) {
      setTimeEstimate(0)
      return
    }
    if (!timeEstimate) return

    const intervalId = setInterval(() => {
      setTimeEstimate(timeEstimate - 1)
    }, 1000)

    return () => clearInterval(intervalId)
  }, [timeEstimate])

  const highestRisk = useMemo(() => {
    if (!projectRiskStats || projectRiskStats.length === 0) {
      return null
    }
    return projectRiskStats.reduce<RiskRanking>((acc, cur) => {
      if (!cur?.risk_ranking) {
        return acc
      }
      if (cur.risk_ranking > acc) {
        return cur.risk_ranking
      }
      return acc
    }, projectRiskStats[0].risk_ranking ?? 1)
  }, [projectRiskStats])

  const navigateRisks = useCallback(() => {
    navigate(
      `/${currentProject?.uuid}/riskreview${
        currentDocument ? `/${currentDocument?.uuid}` : ''
      }`
    )
  }, [navigate, currentProject, currentDocument])

  const getRiskRank = useMemo(() => {
    switch (highestRisk) {
      case RiskRanking.High:
        return 'High'
      case RiskRanking.Medium:
        return 'Medium'
      case RiskRanking.Low:
        return 'Low'
      default:
        return ''
    }
  }, [highestRisk])

  const getHighestRiskCount = useMemo(() => {
    if (!projectRiskStats) {
      return 0
    }
    return projectRiskStats.find((risk) => risk.risk_ranking === highestRisk)
      ?.count
  }, [highestRisk, projectRiskStats])

  const shouldDisplay = useMemo(() => {
    return (
      (riskPipelineStats?.count ?? 0) > 0 ||
      (projectRiskStats?.length ?? 0) > 0 ||
      documentProcessingCount > 0
    )
  }, [
    riskPipelineStats?.count,
    projectRiskStats?.length,
    documentProcessingCount,
  ])

  const hasArchivedDocument = useMemo(() => {
    return documents?.some(
      (d) => d.job_status === 'ARCHIVED' || d.job_status === 'UNARCHIVING'
    )
  }, [documents])

  useEffect(() => {
    const hasArchived = documents?.find((d) => d.job_status === 'ARCHIVED')
    if (hasArchived && currentProject) {
      unarchiveProject(currentProject)
    }
  }, [currentProject, unarchiveProject, documents])

  const risksAreAnalysing = useMemo(() => {
    return (riskPipelineStats?.count ?? 0) > 0
  }, [riskPipelineStats?.count])

  const getStatusDescription = useMemo(() => {
    if (hasArchivedDocument) {
      return <UnarchivingBanner />
    }

    if (documentProcessingCount > 0) {
      return (
        <ProcessingBanner
          timeEstimate={timeEstimate}
          fancyTimeFormat={fancyTimeFormat}
        />
      )
    }

    // TODO this needs a BE endpoint to report current SC processing status
    // if (documentProcessingCount > 0 && supplementaryConditionsAreProcessing) {
    if (supplementaryConditionsAreProcessing) {
      return <SupplementaryConditionsBanner />
    }

    if (projectRiskStats?.length && !risksAreAnalysing) {
      return (
        <CompletedBanner
          navigateRisks={navigateRisks}
          getHighestRiskCount={getHighestRiskCount}
          getRiskRank={getRiskRank}
        />
      )
    }

    if (risksAreAnalysing) {
      return <RiskAnalysingBanner count={riskPipelineStats?.count} />
    }

    return null
  }, [
    documentProcessingCount,
    getHighestRiskCount,
    getRiskRank,
    hasArchivedDocument,
    navigateRisks,
    projectRiskStats?.length,
    riskPipelineStats?.count,
    risksAreAnalysing,
    timeEstimate,
    supplementaryConditionsAreProcessing,
  ])

  return shouldDisplay ? <>{getStatusDescription}</> : null
}

export default ProjectUploadStatus
