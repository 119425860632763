import React from 'react'
import { ProductFruits } from 'react-product-fruits'
import { useAuth0 } from '@auth0/auth0-react'
import { useGetUserProfileQuery } from '../redux/api-slice'

const ProductFruitsComponent = () => {
    const { user: auth0User } = useAuth0()
    const { data: profile } = useGetUserProfileQuery()

    const userInfo = {
        username: auth0User?.sub || '',
        email: profile?.email || '',
        props: { hideInAppCenterLauncher: true }
    }

    return process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE ? (
        <ProductFruits
            workspaceCode={process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE}
            language="en"
            user={userInfo}
        />
    ) : null
}

export default ProductFruitsComponent
