import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { useDispatch, useSelector } from 'react-redux'
import {
  selectHasZoomedRecently,
  selectNewRevision,
  selectSelectedRevision,
  selectSelectedRevisionEdit,
  setNewRevision,
  setOpenRevision,
  setSelectedRevision,
  setSelectedRevisionEdit,
  setTemporaryHighlight,
} from '../../../redux/viewer-slice'
import { useGetTocByDocumentUuidQuery } from '../../../redux/api-slice'
import {
  selectCurrentDocument,
  selectCurrentProject,
} from '../../../redux/application-slice'
import { POSTHOG } from '../../../utils/posthog-constants'
import { usePostHog } from 'posthog-js/react'
import Diff from '../../workflows/comment-table/diff'
import { Revision } from '../../../shared/interfaces/project/document/revision/revision.interface'
import RevisionCommentComponent from '../../overlay/revision-comment-component'
import RevisionCreateComment from '../../overlay/revision-create-comment'
import { skipToken } from '@reduxjs/toolkit/query'
import CommentCardPopover from '../../workflows/comment-table/comment-card-popover'
import RevisionHistoryPopover from '../../revisions/revision-history-popover'
import RevisionStatusBadge from '../../revisions/revision-status-badge'
import { useParams } from 'react-router-dom'
import RevisionCustomLabel from '../../revisions/revision-custom-label'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline'
import { useUpdateRevisionMutation } from '../../../redux/api/api-revisions-slice'

const newRevisionID = 'new_revision'

interface RevisionCreateSidelingProps {
  revision: Revision
  top?: string
  onRevisionChange: (page: number) => void
}

const DocumentViewerRevisionCard = forwardRef<
  HTMLDivElement,
  RevisionCreateSidelingProps
>(({ revision, top, onRevisionChange }, ref) => {
  const currentDocument = useSelector(selectCurrentDocument)
  const newRevision = useSelector(selectNewRevision) as Revision | undefined
  const currentProject = useSelector(selectCurrentProject)
  const selectedRevision = useSelector(selectSelectedRevision)
  const hasZoomedRecently = useSelector(selectHasZoomedRecently)

  const posthog = usePostHog()
  const dispatch = useDispatch()

  const [localRevision, setLocalRevision] = useState<Revision | undefined>(
    revision ? { ...revision, comment: '' } : newRevision
  )
  const selectedRevisionEdit = useSelector(selectSelectedRevisionEdit)
  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false)

  const [updateRevision] = useUpdateRevisionMutation()

  const isCommentOnly = useMemo(
    () => localRevision?.revised_text === null,
    [localRevision]
  )

  const isSelectedRevision = useMemo(
    () => selectedRevision?.id === revision?.id,
    [selectedRevision, revision]
  )

  const isInEditMode = useMemo(
    () => isSelectedRevision && selectedRevisionEdit,
    [isSelectedRevision, selectedRevisionEdit]
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const reset = useCallback(() => {
    dispatch(setOpenRevision(null))
    dispatch(setNewRevision(null))
    dispatch(setTemporaryHighlight(null))
    dispatch(setSelectedRevisionEdit(false))
  }, [dispatch])

  const onUpdateRevision = useCallback(
    (e) => {
      e?.stopPropagation()
      const mappedSegmentQuads = localRevision?.segments?.map((segment) => {
        return {
          ...segment,
          quads: segment?.quads?.map((quad) => {
            return {
              ...quad,
              page: localRevision?.page,
            }
          }),
        }
      })
      const updatePayload = {
        id: revision?.id,
        content: {
          ...localRevision,
          document: currentDocument?.id ? currentDocument.id : undefined,
          documents: currentDocument?.id ? [currentDocument.id] : undefined,
          project: currentProject?.id ? currentProject.id : undefined,
          parent: revision,
          segments: mappedSegmentQuads,
        },
      }
      updateRevision(updatePayload)
      posthog?.capture(POSTHOG.revision_edited, {
        revision_id: revision?.id,
        document_uuid: currentDocument?.uuid,
        project_uuid: currentProject?.uuid,
      })
      dispatch(setOpenRevision(null))
      onRevisionChange(localRevision?.page ?? 1)
      dispatch(setSelectedRevisionEdit(false))
    },
    [
      revision,
      localRevision,
      dispatch,
      updateRevision,
      onRevisionChange,
      currentDocument,
      currentProject,
      posthog,
    ]
  )

  const handleRevisionTextChange = useCallback(
    (e) => {
      setLocalRevision({ ...localRevision, revised_text: e.target.value })
    },
    [localRevision]
  )

  const handleSectionReferenceTextChange = useCallback(
    (e) => {
      setLocalRevision({ ...localRevision, section_reference: e.target.value })
    },
    [localRevision]
  )

  const onSelectRevision = useCallback(
    (e) => {
      if (!revision) {
        return
      }
      dispatch(setSelectedRevision(revision))
    },
    [dispatch, revision]
  )
  const { documentId } = useParams()
  const { data: tocData } = useGetTocByDocumentUuidQuery(
    documentId ? documentId : skipToken
  )

  const onEditRevisionCancel = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setSelectedRevisionEdit(false))
      setLocalRevision((prev) => ({
        ...prev,
        revised_text: revision?.revised_text,
      }))
      onRevisionChange(revision?.page ?? 1)
    },
    [dispatch, onRevisionChange, revision?.page, revision?.revised_text]
  )

  useEffect(() => {
    if (revision) {
      setLocalRevision({
        ...revision,
        comment: '',
      })
    }
  }, [revision])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getSuggestedSectionReference = useMemo(() => {
    const aboveRevisionPage = tocData?.filter(
      (d) => d.page <= (newRevision?.page || 0)
    )
    if (!aboveRevisionPage || aboveRevisionPage.length === 0) {
      return ''
    }
    const suggestedSectionReference = aboveRevisionPage.reduce(
      (prev, current) => {
        if (
          current.quads[0].y2 + current.page >
          (newRevision?.quads?.[0]?.y2 ?? 0) + (newRevision?.page ?? 0)
        ) {
          return prev
        }
        const prevY = prev?.quads[0].y2 + prev?.page
        const currentY = current.quads[0].y2 + current.page
        return prevY > currentY ? prev : current
      }
    )?.title
    return suggestedSectionReference
  }, [tocData, newRevision?.page, newRevision?.quads])

  const sectionReferenceInput = useMemo(() => {
    return isInEditMode ? (
      <input
        type="text"
        onChange={handleSectionReferenceTextChange}
        value={localRevision?.section_reference ?? ''}
        placeholder={'Enter section reference'}
        className="custom-scroll m-0 block w-full resize-none rounded border-indigo-300 p-0.5 text-xs"
      />
    ) : (
      <div
        className={
          'overflow-hidden text-ellipsis whitespace-nowrap border border-transparent p-0.5 text-xs text-gray-500'
        }
      >
        {localRevision?.section_reference ?? 'No section reference'}
      </div>
    )
  }, [
    isInEditMode,
    handleSectionReferenceTextChange,
    localRevision?.section_reference,
  ])

  const revisionTextEdit = useMemo(() => {
    return (
      <div className="flex">
        <TextareaAutosize
          data-cy="revision-edit-textarea"
          className="custom-scroll start-0 m-0 block w-full resize-none overflow-x-hidden whitespace-pre text-wrap rounded border-indigo-300 p-0.5 text-sm"
          onChange={handleRevisionTextChange}
          value={localRevision?.revised_text ?? ''}
          placeholder={'Edit revision content here'}
        />
      </div>
    )
  }, [handleRevisionTextChange, localRevision?.revised_text])

  const onClickCommentsExpand = useCallback(
    (e) => {
      e.stopPropagation()
      onRevisionChange(revision?.page ?? 1)
      setIsCommentsExpanded((prev) => !prev)
    },
    [onRevisionChange, revision?.page]
  )

  const remainingCommentCount = useMemo(() => {
    if (!revision.comments?.length) {
      return 0
    }
    if (revision?.comments?.length >= 0 && revision?.comments?.length <= 2) {
      return 0
    }
    return revision?.comments?.length - 2
  }, [revision])

  const onEditClicked = useCallback(
    (value) => {
      dispatch(setSelectedRevisionEdit(value))
    },
    [dispatch]
  )

  const revisionContent = useMemo(() => {
    return (
      <>
        <div
          className={
            'group flex w-full flex-grow-0 items-center justify-between p-2'
          }
        >
          <RevisionCustomLabel revision={revision} />
          <div className="flex flex-shrink-0 items-center">
            <RevisionStatusBadge revision={revision} />
            {revision ? (
              <CommentCardPopover
                source={'document_viewer'}
                setEditRevision={onEditClicked}
                selectedDocument={currentDocument}
                revision={revision}
                onRevisionChange={onRevisionChange}
              />
            ) : null}
          </div>
        </div>
        <div className="border-t px-2">{sectionReferenceInput}</div>
        {isInEditMode && (
          <div className="border-t p-2">
            {!isCommentOnly && (
              <div className="flex flex-col">{revisionTextEdit}</div>
            )}
            <div className={'flex justify-end gap-2 p-1'}>
              <button
                type="button"
                className="self-start rounded border bg-white px-4 py-1 text-sm font-semibold shadow-sm hover:bg-gray-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onEditRevisionCancel}
              >
                Cancel
              </button>
              <button
                type="button"
                className="self-start rounded border border-indigo-600 bg-indigo-600 px-4 py-1 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                onClick={onUpdateRevision}
              >
                Save changes
              </button>
            </div>
          </div>
        )}
        {!isCommentOnly && (
          <div className={'space-y-2 border-t p-2'}>
            <div className="overflow-y-scroll text-gray-700">
              <Diff
                inputA={revision?.original_text ?? ''}
                inputB={localRevision?.revised_text ?? ''}
              />
            </div>
            <RevisionHistoryPopover revision={revision} />
          </div>
        )}
        <div className={`border-t py-1`}>
          {revision?.comments?.[0] ? (
            <RevisionCommentComponent
              comment={revision?.comments?.[0]}
              source={'document'}
              revision={revision}
            />
          ) : null}
          {remainingCommentCount > 0 ? (
            <button
              onClick={onClickCommentsExpand}
              data-cy="revision-comments-expand-button"
              className={
                'flex w-full items-center justify-start gap-1 border-y p-1 px-2 text-xs text-gray-500 hover:bg-gray-100'
              }
            >
              <span>
                {isCommentsExpanded ? 'Hide' : 'Show'} ({remainingCommentCount})
                Comment(s)
              </span>
              {isCommentsExpanded ? (
                <ChevronUpIcon width={18} />
              ) : (
                <ChevronDownIcon width={18} />
              )}
            </button>
          ) : null}
          {isCommentsExpanded &&
            revision?.comments?.map((comment, index) => {
              if (
                index === 0 ||
                index === (revision?.comments?.length ?? 0) - 1
              ) {
                return null
              }
              return (
                <div key={comment.id}>
                  <RevisionCommentComponent
                    comment={comment}
                    source={'document'}
                    revision={revision}
                  />
                </div>
              )
            })}
          {revision?.comments?.[revision?.comments?.length - 1] &&
          revision?.comments?.length >= 2 ? (
            <RevisionCommentComponent
              comment={revision?.comments?.[revision?.comments?.length - 1]}
              source={'document'}
              revision={revision}
            />
          ) : null}
          {revision && revision.id && (
            <RevisionCreateComment
              revision={revision}
              onRevisionChange={onRevisionChange}
            />
          )}
        </div>
      </>
    )
  }, [
    revision,
    onEditClicked,
    currentDocument,
    onRevisionChange,
    sectionReferenceInput,
    isInEditMode,
    isCommentOnly,
    revisionTextEdit,
    onEditRevisionCancel,
    onUpdateRevision,
    localRevision?.revised_text,
    remainingCommentCount,
    onClickCommentsExpand,
    isCommentsExpanded,
  ])

  return (
    <div
      data-id={revision?.id ?? newRevisionID}
      ref={ref}
      id={revision?.id?.toString() ?? newRevisionID.toString()}
      className={`revision-sideling absolute mb-2 ml-4 rounded pr-2 ${hasZoomedRecently && selectedRevision?.id !== revision?.id ? '' : 'transition-[top]'} ${selectedRevision?.id === revision?.id ? 'z-20 border-l-2 border-gray-500' : 'z-10 border-l hover:border-gray-500'}`}
      style={{
        top,
        opacity:
          hasZoomedRecently && selectedRevision?.id !== revision?.id ? 0 : 1,
      }}
    >
      <div
        onClick={onSelectRevision}
        className={`relative w-96 cursor-pointer border border-gray-300 bg-white text-left text-sm ${selectedRevision?.id === revision?.id ? 'shadow-lg' : ''}`}
      >
        {revisionContent}
      </div>
    </div>
  )
})

DocumentViewerRevisionCard.displayName = 'RevisionCreateSideling'

export default DocumentViewerRevisionCard
