export const POSTHOG = {
  // Authentication & User Actions
  user_logged_in: 'User logged in',
  user_logged_out: 'User logged out',
  invite_users: 'Invite Users',

  // Chat & Questions
  chat_feedback_submitted: 'Chat feedback submitted',
  chat_feedback_thumbs_down: 'Chat feedback thumbs down',
  chat_feedback_thumbs_up: 'Chat feedback thumbs up',
  chat_renamed: 'Chat renamed',
  chat_sent_message: 'Chat sent message',
  chat_session_opened: 'Chat session opened',
  chat_session_saved: 'Chat session saved',
  chat_slow: 'Chat slow',
  chat_source_clicked: 'Chat source clicked',
  chat_workflow_opened: 'Chat workflow opened',
  document_chat_conflicts_opened: 'Document chat conflicts opened',
  question_source_clicked: 'Question source clicked',
  question_submitted: 'Question submitted',

  // Comments & Revisions
  comment_created_opened: 'Comment create opened',
  comment_table_edited: 'Comment table edited',
  comments_workflow_opened: 'Comments workflow opened',
  download_comment_table: 'Download comment table',
  revision_created: 'Revision created',
  revision_created_opened: 'Revision create opened',
  revision_deleted: 'Revision deleted',
  revision_edited: 'Revision edited',
  revision_status_changed: 'Revision status changed',

  // Documents
  document_change_comment_edited: 'Document change comment edited',
  document_change_status_updated: 'Document change status updated',
  document_deleted: 'Document deleted',
  document_opened: 'Document opened',
  document_renamed: 'Document renamed',
  document_revision_uploaded: 'Document revision uploaded',
  document_uploaded: 'Document uploaded',
  documents_workflow_opened: 'Documents workflow opened',
  page_changed: 'Page changed',

  // Folders
  folder_created: 'Folder created',
  folder_deleted: 'Folder deleted',
  folder_renamed: 'Folder renamed',

  // Labels & Highlighting
  custom_label_applied: 'Custom label applied',
  custom_label_clicked: 'Custom label clicked',
  custom_label_created: 'Custom label created',
  highlight_created: 'Highlight created',
  text_selected: 'Text selected',

  // Projects
  project_created: 'Project created',
  project_deleted: 'Project deleted',
  project_opened: 'Project opened',
  project_renamed: 'Project renamed',

  // References & Definitions
  definition_clicked: 'Definition clicked',

  // Risk Management
  custom_risklisttemplate_created: 'Custom Risk Checklist created',
  custom_risklisttemplate_deleted: 'Custom Risk Checklist deleted',
  custom_risklisttemplate_riskreview_run_called:
    'Risk Review triggered for Custom Risk Checklist',
  custom_riskpipelinedefinition_created: 'Custom Risk created',
  custom_riskpipelinedefinition_deleted: 'Custom Risk deleted',
  custom_riskpipelinedefinition_edited: 'Custom Risk edited',

  export_risk_excel: 'Export Risk to Excel',
  export_risk_pdf: 'Export Risk to PDF',

  project_risk_comment_edited: 'Project risk comment edited',
  // TODO Below isn't used
  project_risk_status_updated: 'Project risk status updated',
  // TODO Below isn't used
  risk_labelling_label_opened: 'Risk labelling label opened',
  // TODO Below isn't used
  risk_labelling_opened: 'Risk labelling opened',

  // TODO The Risk Review feedback and Chat Feedback submission events are being shared
  risk_review_item_feedback_thumbs_down:
    'Risk Review item feedback thumbs down',
  risk_review_item_feedback_thumbs_up: 'Risk Review item feedback thumbs up',

  risk_review_item_status_approved: 'Risk Review item status approved',
  risk_review_item_status_not_approved: 'Risk Review item status not approved',
  risk_review_list_opened: 'Risk Review list opened',
  risk_review_risk_expanded: 'Risk Review risk expanded',
  risk_review_workflow_opened: 'Risk Review workflow opened',

  // Search & Filtering
  clause_filtering_opened: 'Clause filtering opened',
  clause_filtering_workflow_opened: 'Clause filtering workflow opened',
  document_typesense_result_clicked: 'Document typesense result clicked',
  document_typesense_search: 'Document typesense search',
  // TODO Below isn't used
  keyword_search_finished: 'Keyword search finished',
  keyword_search_result_clicked: 'Keyword search result clicked',
  keyword_search_submitted: 'Keyword search submitted',
  // TODO Below isn't used
  project_typesense_result_clicked: 'Project typesense result clicked',
  // TODO Below isn't used
  project_typesense_search: 'Project typesense search',
  toc_item_clicked: 'Table of Contents item clicked',
  opened_toc_from_document_viewer:
    'Opened Table of Contents from document viewer',
  opened_definitions_from_document_viewer:
    'Opened Definitions from document viewer',
  opened_search_from_document_viewer: 'Opened Search from document viewer',

  // Supplementary Features
  // TODO Below isn't used
  amendment_clicked: 'Amendment clicked',
  // TODO Below isn't used
  checklist_create: 'Checklist Create',
  // TODO Below isn't used
  checklist_question_updated: 'Checklist question updated',
  // TODO Below isn't used
  checklist_run_all_questions: 'Checklist run all questions',
  duplicator_opened: 'Duplicator opened',
  explain_this_clause_clicked: 'Explain this clause clicked',
  supplementary_condition_qa_created: 'Supplementary condition QA created',
  supplementary_condition_qa_deleted: 'Supplementary condition QA deleted',
  supplementary_condition_qa_updated: 'Supplementary condition QA updated',
  supplementary_conditions_workflow_opened:
    'Supplementary conditions workflow opened',

  // Navigation
  command_bar_opened: 'Command bar opened',
}
