import { useCallback, useMemo } from 'react'
import {
  useGetUserProjectModelPermissionsQuery,
  useGetOrganisationMembersQuery,
  useGetUserProfileQuery,
  useAssignUserRoleMutation,
} from '../redux/api-slice'
import { skipToken } from '@reduxjs/toolkit/query'
import { UserRoles } from '../shared/interfaces/user/permissions.inteface'

// Interfaces
interface ProjectPermissions {
  canEdit: boolean
  canDelete: boolean
  canLeave: boolean
}

interface UserProfile {
  id?: number
  email?: string
  organisation?: {
    id: string
  }
}

interface OrganisationMember {
  id: string | number
  email: string
  organisation_roles: string[]
  project_roles?: {
    [key: string]: string[]
  }
}

interface ProjectModelPermission {
  id: string
  permissions: string[]
}

interface UsePermissionsOptions {
  currentProjectId?: string
  userProfile?: UserProfile
}

type OrganisationRole =
  | typeof UserRoles.ORGANISATION_ADMIN_ROLE
  | typeof UserRoles.ORGANISATION_MEMBER_ROLE

export const usePermissions = (options: UsePermissionsOptions = {}) => {
  const { currentProjectId, userProfile } = options
  const [assignUserRole] = useAssignUserRoleMutation()

  const {
    data: userProjectModelPermissions,
    isLoading: isProjectPermissionsLoading,
  } = useGetUserProjectModelPermissionsQuery(
    currentProjectId ? undefined : skipToken
  )

  const {
    data: organisationMembers,
    isLoading: isOrganisationsMembersLoading,
  } = useGetOrganisationMembersQuery(userProfile?.organisation?.id ?? skipToken)

  const { data: userProfileData, isLoading: isUserProfileLoading } =
    useGetUserProfileQuery(undefined)

  const getProjectPermissions = useCallback(
    (projectId: string | undefined): Omit<ProjectPermissions, 'canLeave'> => {
      if (!projectId || !userProjectModelPermissions) {
        return { canEdit: false, canDelete: false }
      }

      const projectPermissions =
        (userProjectModelPermissions as ProjectModelPermission[]).find(
          (project) => project.id === projectId
        )?.permissions || []

      return {
        canEdit: projectPermissions.includes('change_projectmodel'),
        canDelete: projectPermissions.includes('delete_projectmodel'),
      }
    },
    [userProjectModelPermissions]
  )

  const organisationRoles = useCallback(
    (email: string | undefined): string[] => {
      if (!email || !organisationMembers) {
        return []
      }

      const memberProfile = (organisationMembers as OrganisationMember[]).find(
        (member) => member.email === email
      )

      if (!memberProfile) {
        return []
      }

      return memberProfile.organisation_roles
    },
    [organisationMembers]
  )

  const canLeave = useMemo(() => {
    if (!currentProjectId || !organisationMembers || !userProfileData?.id) {
      return false
    }

    const userProjectRoles =
      organisationMembers.find((user) => Number(user.id) === userProfileData.id)
        ?.project_roles?.[currentProjectId] ?? []

    return !userProjectRoles.includes('project_admin')
  }, [currentProjectId, organisationMembers, userProfileData?.id])

  const permissionsForProject = useMemo(
    () => ({
      ...getProjectPermissions(currentProjectId),
      canLeave,
    }),
    [currentProjectId, getProjectPermissions, canLeave]
  )

  const getUserOrganisationPermissions = useMemo(
    () => organisationRoles(userProfile?.email),
    [userProfile?.email, organisationRoles]
  )

  const assignOrganisationRole = useCallback(
    async (memberId: number, role: OrganisationRole) => {
      if (!userProfile?.organisation?.id) {
        throw new Error('No organisation ID available')
      }

      try {
        await assignUserRole({
          user_id: memberId,
          role: role,
          content_type: 'organisationmodel',
          object_id: userProfile.organisation.id,
        })
      } catch (error) {
        console.error('Failed to assign role:', error)
        throw error
      }
    },
    [assignUserRole, userProfile?.organisation?.id]
  )

  return {
    ...permissionsForProject,
    getUserOrganisationPermissions,
    assignOrganisationRole,
    isPermissionsLoading: {
      projectPermissions: isProjectPermissionsLoading,
      userOrganisationPermissions: isOrganisationsMembersLoading,
      userProfile: isUserProfileLoading,
    },
  }
}
