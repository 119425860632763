import React from 'react'
import { Banner } from './common-banner'
import {
  CheckCircleIcon,
  ClockIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid'
import { Tooltip } from '@mantine/core'
import { useDispatch } from 'react-redux'
import { setModal } from '../../redux/application-slice'
import { MODAL_TYPES } from '../modals/modal-controller'

const SupplementaryConditionsText = ({
  plural = true,
}: {
  plural?: boolean
}) => (
  <Tooltip
    label={
      'Supplementary Conditions are modifications to the original contract document which requires additional processing.'
    }
    position="bottom"
    multiline
    w={290}
    offset={44}
    className="!text-[16px]"
  >
    <span className="border-b border-dashed border-amber-700">
      {plural ? 'Supplementary Conditions' : 'Supplementary Condition'}
    </span>
  </Tooltip>
)

const SC_IN_PROCESSING_MESSAGE = (
  <div className="inline">
    <SupplementaryConditionsText /> are being processed. Some information will
    be inaccurate until processing is complete. Please check back tomorrow.
  </div>
)

const UNARCHIVING_MESSAGE =
  'Your documents are being unarchived, this will take a few minutes.'
const ANALYSING_DOCUMENTS_MESSAGE = 'Provision is analysing your documents'
const CALCULATING_MESSAGE = 'Calculating...'
const ESTIMATED_TIME_MESSAGE = 'Estimated time:'
const GO_TO_RISK_REVIEW = 'Go to Risk Review'
const RISKS_BEING_ANALYSED = (count: number) =>
  `${count} Risk(s) are currently being analysed`

const SC_RISK_PROCESSING_MESSAGE = (
  <div className="inline">
    <SupplementaryConditionsText /> are being processed, and Risk Review will
    update once complete. Please check back tomorrow.
  </div>
)

const LOADING_MESSAGE = 'Loading...'

const UPLOAD_DOCUMENT = 'Upload a Document'
const COMPLETED_MESSAGE = (
  getHighestRiskCount?: number,
  getRiskRank?: string
) =>
  `Project Analysis Complete: ${getHighestRiskCount ?? null} ${
    getRiskRank ?? null
  } risk(s)`

interface RiskReviewActionProps {
  navigateRisks: () => void
  getHighestRiskCount?: number
  getRiskRank?: string
}

export const UnarchivingBanner: React.FC = () => (
  <Banner
    icon={InformationCircleIcon}
    colour="blue"
    message={UNARCHIVING_MESSAGE}
  />
)

export const ProcessingBanner: React.FC<{
  timeEstimate: number | null
  fancyTimeFormat: (duration: number) => string
}> = ({ timeEstimate, fancyTimeFormat }) => (
  <Banner
    icon={ClockIcon}
    colour="blue"
    message={ANALYSING_DOCUMENTS_MESSAGE}
    action={
      <p className="text-normal flex font-semibold text-blue-500">
        {timeEstimate !== null ? (
          <div className="block text-left tabular-nums">
            {ESTIMATED_TIME_MESSAGE} {fancyTimeFormat(timeEstimate ?? 0)}
          </div>
        ) : (
          <span>{CALCULATING_MESSAGE}</span>
        )}
      </p>
    }
  />
)

export const CompletedBanner: React.FC<RiskReviewActionProps> = ({
  navigateRisks,
  getHighestRiskCount,
  getRiskRank,
}) => (
  <Banner
    icon={CheckCircleIcon}
    colour="green"
    message={COMPLETED_MESSAGE(getHighestRiskCount, getRiskRank)}
    action={
      <div className="-mx-1.5 -my-1.5 mr-0">
        <button
          onClick={navigateRisks}
          className="inline-flex items-center gap-2 rounded-md border border-[#ffffff00] bg-green-50 p-1.5 text-sm font-medium text-green-800 transition-colors hover:border-green-600 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
        >
          {GO_TO_RISK_REVIEW}
          <span aria-hidden="true"> &rarr;</span>
        </button>
      </div>
    }
  />
)

export const RiskAnalysingBanner: React.FC<{ count?: number }> = ({
  count,
}) => (
  <Banner
    icon={ClockIcon}
    colour="blue"
    message={RISKS_BEING_ANALYSED(count ?? 0)}
  />
)

export const RiskAnalysingBecauseSCsBanner: React.FC = () => (
  <Banner
    icon={ClockIcon}
    colour="amber"
    message={SC_RISK_PROCESSING_MESSAGE}
    disableInbuiltSpacing
  />
)

export const SupplementaryConditionsBanner: React.FC = () => (
  <Banner icon={ClockIcon} colour="amber" message={SC_IN_PROCESSING_MESSAGE} />
)

export const DocumentChangeBanner: React.FC<{
  isLoading: boolean
  isInReview: boolean
  hasChanges: boolean
  onUploadClick: () => void
}> = ({ isLoading, isInReview, hasChanges, onUploadClick }) => {
  const dispatch = useDispatch()
  const openTutorial = () =>
    dispatch(setModal({ modal: MODAL_TYPES.SC_TUTORIAL }))

  const uploadSCMessage = (
    <span className="inline">
      Upload a <SupplementaryConditionsText plural={false} /> in the Documents
      workflow to get started.{' '}
      <span
        className="cursor-pointer text-blue-400 underline"
        onClick={openTutorial}
      >
        Watch this tutorial on how to use Supplementary Conditions.
      </span>
    </span>
  )

  return isLoading ? null : (
    <Banner
      icon={ClockIcon}
      colour="amber"
      message={
        isLoading
          ? LOADING_MESSAGE
          : isInReview
            ? SC_IN_PROCESSING_MESSAGE
            : uploadSCMessage
      }
      action={
        hasChanges || !isInReview ? (
          <button
            onClick={onUploadClick}
            className="whitespace-nowrap font-medium text-amber-700 hover:text-yellow-600"
          >
            {UPLOAD_DOCUMENT}
            <span aria-hidden="true"> &rarr;</span>
          </button>
        ) : null
      }
    />
  )
}
