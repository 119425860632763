import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'
import '@mantine/core/styles.css'

import App from './App'

import reportWebVitals from './reportWebVitals'
import LogRocket from 'logrocket'

import { Auth0Provider } from '@auth0/auth0-react'
import AuthWrapper from './components/auth/auth-wrapper'

import { Provider } from 'react-redux'
import { store } from './redux/store'

import * as Sentry from '@sentry/react'

import { PostHogProvider } from 'posthog-js/react'
import posthog from 'posthog-js'

import ProductFruitsComponent from './components/product-fruits'

import { WebsocketProvider } from './contexts/websocket-context'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
} from 'react-router-dom'

import { createTheme, MantineProvider } from '@mantine/core'

const theme = createTheme({
  /** One day we will override theme styles. Until then this is dormant. */
  /** See: https://mantine.dev/theming/colors/ for details/examples */
})

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: 'https://rick.useprovision.com',
})

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  replaysSessionSampleRate: 0.5,
  replaysOnErrorSampleRate: 1.0,
  environment: process.env.REACT_APP_ENVIRONMENT,
  tracesSampleRate: 0.1,
  tracePropagationTargets: ['localhost', /^https:\/\/useprovision\.com\/api/],
  profilesSampleRate: 0.1,
})

if (process.env.REACT_APP_LOGROCKET_ID) {
  LogRocket.init(process.env.REACT_APP_LOGROCKET_ID, {
    network: {
      requestSanitizer: (request) => {
        request.headers['authorization'] = null
        return request
      },
    },
  })
}

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Auth0Provider
    domain={process.env.REACT_APP_AUTH0_DOMAIN}
    clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
    cacheLocation="localstorage"
    authorizationParams={{
      redirect_uri: window.location.origin,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
    }}
  >
    <Provider store={store}>
      <AuthWrapper>
        <PostHogProvider client={posthog}>
          <WebsocketProvider>
            <MantineProvider theme={theme}>
              <App />
              <ProductFruitsComponent />
            </MantineProvider>
          </WebsocketProvider>
        </PostHogProvider>
      </AuthWrapper>
    </Provider>
  </Auth0Provider>
)

reportWebVitals()
