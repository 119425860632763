import { Popover } from '@headlessui/react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import React, { useState, useCallback } from 'react'
import { usePopper } from 'react-popper'
import { EllipsisVerticalIcon } from '@heroicons/react/24/solid'
import { Tooltip } from 'react-tooltip'
import { selectSupplementaryConditionsAreProcessing } from '../../../redux/application-slice'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'

interface ExportPopoverProps {
  onClickExportToExcel?: () => void
  onClickExportToPDF?: () => void
  exportTypes: ('pdf' | 'excel')[]
  isDisabled?: boolean
  compact?: boolean
}

const ExportPopover: React.FC<ExportPopoverProps> = ({
  onClickExportToExcel,
  onClickExportToPDF,
  isDisabled = false,
  exportTypes,
  compact = false,
}) => {
  const supplementaryConditionsAreProcessing = useSelector(
    selectSupplementaryConditionsAreProcessing
  )

  const [exportButtonRef, setExportButtonRef] =
    useState<HTMLButtonElement | null>(null)
  const [exportPanelRef, setExportPanelRef] = useState<HTMLDivElement | null>(
    null
  )
  const { styles: exportStyles, attributes: exportAttributes } = usePopper(
    exportButtonRef,
    exportPanelRef
  )
  const handleExportToExcel = useCallback(
    (close: () => void) => {
      if (supplementaryConditionsAreProcessing) {
        toast.info(
          'You can still download the data here, but it does not include the recently uploaded SC, which is still being processed at this time. Thank you for your patience.',
          { toastId: 'sc-processing' }
        )
      }
      onClickExportToExcel?.()
      close()
    },
    [onClickExportToExcel, supplementaryConditionsAreProcessing]
  )

  const handleExportToPDF = useCallback(
    (close: () => void) => {
      if (supplementaryConditionsAreProcessing) {
        toast.info(
          'You can still download the data here, but it does not include the recently uploaded SC, which is still being processed at this time. Thank you for your patience.',
          { toastId: 'sc-processing' }
        )
      }
      onClickExportToPDF?.()
      close()
    },
    [onClickExportToPDF, supplementaryConditionsAreProcessing]
  )

  return (
    <>
      <Popover className="relative flex items-center justify-center">
        {({ close }) => (
          <>
            <Popover.Button
              className={`rounded ${
                !isDisabled ? ' bg-white hover:bg-gray-100' : 'bg-white'
              }`}
              tabIndex={-1}
              ref={setExportButtonRef}
              disabled={isDisabled}
              aria-label="Additional options"
              data-tooltip-id={!isDisabled ? '' : `export-questions-id`}
              data-tooltip-content="Exporting is not available while documents are still processing"
            >
              {compact ? (
                <div className={'p-1'}>
                  <EllipsisVerticalIcon width={24} />
                </div>
              ) : (
                <div
                  className={`flex rounded px-2.5 py-1.5 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 ${
                    !isDisabled
                      ? ' bg-white hover:bg-gray-100'
                      : ' bg-gray-200 '
                  }'}`}
                >
                  <ArrowDownTrayIcon className="mr-2 h-5 w-5" />
                  Export
                </div>
              )}
            </Popover.Button>
            <Popover.Panel
              ref={setExportPanelRef}
              style={exportStyles.popper}
              {...exportAttributes.popper}
              className="z-50 rounded border bg-white shadow"
            >
              {exportTypes.includes('excel') && (
                <button
                  className="w-36 px-4 py-2 text-left hover:bg-gray-100"
                  onClick={() => handleExportToExcel(close)}
                >
                  Export to Excel
                </button>
              )}
              {exportTypes.includes('pdf') && (
                <button
                  className="w-36 px-4 py-2 text-left hover:bg-gray-100"
                  onClick={() => handleExportToPDF(close)}
                >
                  Export to PDF
                </button>
              )}
            </Popover.Panel>
          </>
        )}
      </Popover>
      <Tooltip id="export-questions-id" />
    </>
  )
}

export default ExportPopover
