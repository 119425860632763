import React, { Fragment, useCallback, useMemo } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch } from 'react-redux'
import { setModal } from '../../redux/application-slice'

interface LearnQuestionsModalProps {
  open: boolean
  loomLink: string
  modalTitle: string
}

function WatchLoomModal({
  open,
  loomLink,
  modalTitle,
}: LearnQuestionsModalProps) {
  const dispatch = useDispatch()

  const onClose = useCallback(() => {
    dispatch(setModal(null))
  }, [dispatch])

  const dialogContent = useMemo(() => {
    return (
      <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:h-[calc(100vh-200px)] sm:w-[calc(100vw-200px)]">
        <div className="h-full w-full">
          <div className="flex h-full flex-col p-6">
            <Dialog.Title
              as="h3"
              className="mb-4 text-lg font-semibold leading-6 text-gray-900"
            >
              {modalTitle}
            </Dialog.Title>
            <div className="flex-grow">
              <iframe
                title={modalTitle}
                src={loomLink}
                frameBorder="0"
                allowFullScreen
                className="h-full w-full"
              ></iframe>
            </div>
            <div className="mt-6 flex justify-end">
              <button
                type="button"
                className="w-full rounded-md bg-blue-500 px-3 py-2 text-sm font-semibold text-white shadow-sm transition-colors  hover:bg-blue-600"
                onClick={onClose}
              >
                Go Back to Chat
              </button>
            </div>
          </div>
        </div>
      </Dialog.Panel>
    )
  }, [onClose, loomLink, modalTitle])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              {dialogContent}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default WatchLoomModal
